import { UpdateActualityInput } from '@warebee/frontend/data-access-api-graphql';

export const actualityAutoAnalyzeStatus = [
  'none',
  'progress',
  'done',
  'error',
] as const;
export type ActualityAutoAnalyzeStatus =
  (typeof actualityAutoAnalyzeStatus)[number];

export const actualityMainViewTypes = ['layout', 'table', 'timeline'] as const;
export type ActualityMainViewType = (typeof actualityMainViewTypes)[number];

export type ActualityDatasetPatch = Pick<
  UpdateActualityInput,
  'activityFeedId' | 'assignmentId' | 'layoutId' | 'itemSetId' | 'orderSetId'
>;

export const timelineFilterByPerformance = [
  'all',
  'underperform',
  'underperform20',
] as const;

export type TimelineFilterByPerformance =
  (typeof timelineFilterByPerformance)[number];

export const timelineFilterByType = [
  'user',
  'proccess',
  'performance',
] as const;

export type TimelineFilterByType = (typeof timelineFilterByType)[number];

export type ActualityExtraSettings = {
  runAnalyseForNewEvents: boolean;
  refreshInterval: number;
};
