import { detectAll } from 'jschardet';
import React, { useEffect, useState } from 'react';

interface FileEncodingCheckProps {
  file: File;
  onEncodingDetected: (encoding: string) => React.ReactNode | null;
}

const FileEncodingCheck: React.FC<FileEncodingCheckProps> = ({
  file,
  onEncodingDetected,
}): JSX.Element => {
  const [encoding, setEncoding] = useState<string | null>(null);

  useEffect(() => {
    const checkEncoding = async () => {
      const reader = new FileReader();
      reader.onload = e => {
        const result = e.target?.result;
        if (typeof result === 'string') {
          const detectedEncodings = detectAll(result);
          // Sort encodings by confidence and get the highest
          const bestMatch = detectedEncodings.sort(
            (a, b) => b.confidence - a.confidence,
          )[0];
          // If the best match is ASCII or ISO-8859-1, verify if it's actually UTF-8
          const finalEncoding = ['ascii', 'ISO-8859-1'].includes(
            bestMatch.encoding.toLowerCase(),
          )
            ? verifyUtf8(result)
            : bestMatch.encoding;
          setEncoding(finalEncoding);
        }
      };
      // Increase the sample size to 16KB for better detection
      reader.readAsBinaryString(file.slice(0, 16384));
    };

    checkEncoding();
  }, [file]);

  // Helper function to verify if ASCII/ISO-8859-1 detected content is actually UTF-8
  const verifyUtf8 = (content: string): string => {
    try {
      decodeURIComponent(escape(content));
      return 'UTF-8';
    } catch {
      return 'ASCII';
    }
  };

  return encoding ? <>{onEncodingDetected(encoding)}</> : null;
};

export default FileEncodingCheck;
