import {
  AnalyzeResultFragment,
  AnalyzeResultOrderDetailsFragment,
  AnalyzeResultOrderSummaryFragment,
  AnalyzeResultWaypointFragment,
  AssignmentDiffItemFragment,
  BuildSimulationLayoutRouteDocument,
  BuildSimulationLayoutRouteQuery,
  BuildSimulationLayoutRouteQueryVariables,
  LayoutPlaneRouteFragment,
  LayoutRouteOrder,
  MeasurementSystem,
  OptimizationRunFragment,
  OptimizationRunSourceType,
  OptimizationRunStatus,
  OptimizationRunStatusFragment,
  SortDirection,
} from '@warebee/frontend/data-access-api-graphql';
import { getAssignmentDiffFiltered } from '@warebee/shared/export-converter';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { atom, selector } from 'recoil';
import { secureClient } from '../../GraphQLClient';
import { AsyncLoadStatus, DatasetTableState } from '../../common/types';
import { PicklistRoute } from '../../layout/features/features.types';
import { stageStateById } from '../../layout/stage/stage.state';
import { viewerSelectedPlaneId } from '../../layout/viewer/store/viewer.state';
import { warehouseMeasurementSystem } from '../../store/warehouse.state';
import { OptimizationAssignmentDiffTableColumn } from '../panels/optimization/OptimizationAssignmentDiffTable';
import { allocationAssignmentId } from './allocation/allocation.state';
import { analyzeJobDetailsSelectedRow } from './analyze.state';
import { getRoutingPolicyInput } from './routingPolicy/routingPolicy.helper';
import { routingPolicy } from './routingPolicy/routingPolicy.state';
import { loadWaypointHeatmap } from './simulation.helper';
import {
  simulationLayoutActiveAgentId,
  simulationLayoutSelectedOrderId,
  simulationLayoutShowCongestion,
} from './simulation.layout.state';
import {
  simulationAssignmentId,
  simulationCurrent,
  simulationCurrentId,
  simulationEffectiveAssignmentId,
} from './simulation.state';
import {
  AssignmentDiff,
  AssignmentDiffFull,
  OptimisationViewMode,
} from './simulation.types';

const getKey = (postfix: string) =>
  `warebee-simulation-optimisation-${postfix}`;

export const optimisationResult = atom<OptimizationRunFragment>({
  key: getKey('result'),
  default: null,
});

export const optimisationResultStatus = atom<OptimizationRunStatusFragment>({
  key: getKey('result-status'),
  default: null,
});

export const optimisationViewModeAtom = atom<OptimisationViewMode>({
  key: getKey('view-mode-atom'),
  default: OptimisationViewMode.Compare,
});

export const optimisationViewMode = selector<OptimisationViewMode>({
  key: getKey('vew-mode'),
  get: ({ get }) => get(optimisationViewModeAtom),
  set: ({ get, set }, value) => {
    set(optimisationViewModeAtom, value);

    const optimisationMode =
      get(optimisationResult)?.optimizationSettings?.source?.type;
    switch (value) {
      case OptimisationViewMode.Original:
        set(
          simulationEffectiveAssignmentId,
          optimisationMode === OptimizationRunSourceType.ALLOCATION_RUN
            ? get(allocationAssignmentId)
            : get(simulationAssignmentId),
        );
        break;
      case OptimisationViewMode.Optimised:
      case OptimisationViewMode.Compare:
        set(
          simulationEffectiveAssignmentId,
          get(optimisationResult)?.resultAssignment?.id,
        );
        break;
    }
  },
});

export const optimisationAnalyzeId = selector<string>({
  key: getKey('analyze-id'),
  get: ({ get }) => get(optimisationResult)?.analyzeResult?.id,
});

export const optimisationAnalyzeResult = selector<AnalyzeResultFragment>({
  key: getKey('analyze-result'),
  get: ({ get }) => get(optimisationResult)?.analyzeResult,
});

export const optimisationWaypointHeatmap = selector<
  AnalyzeResultWaypointFragment[]
>({
  key: getKey('locations-waypoints-heatmap'),
  get: async ({ get }) => {
    const analyzeId = get(optimisationAnalyzeId);
    const areaId = get(viewerSelectedPlaneId);
    const showCongestion = get(simulationLayoutShowCongestion);

    if (!showCongestion) return null;

    return await loadWaypointHeatmap({ analyzeId, areaId });
  },
});

// Optimisation - Analyze - Orders and Picklists

export const optimisationAnalyzeOrdersListData = atom<
  AnalyzeResultOrderSummaryFragment[]
>({
  key: getKey('analyze-orders-compare-data'),
  default: [],
});

export const optimisationSelectedOrder =
  selector<AnalyzeResultOrderSummaryFragment>({
    key: getKey('selected-order'),
    get: ({ get }) => {
      const selected = get(simulationLayoutSelectedOrderId);
      const list = get(optimisationAnalyzeOrdersListData);

      return _.find(list, order => order.id === selected);
    },
  });

export const optimisationAnalyzeOrderDetails =
  atom<AnalyzeResultOrderDetailsFragment>({
    key: getKey('analyze-order-details'),
    default: null,
  });

export const optimisationAnalyzeOrderDetailsLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('analyze-order-details-load-status'),
  default: AsyncLoadStatus.None,
});

export const optimisationSelectedPicklistId = atom<string>({
  key: getKey('selected-picklist-id'),
  default: null,
});

export const optimisationPicklistRoutes = atom<PicklistRoute[]>({
  key: getKey('picklist-routes'),
  default: null,
});

export const optimisationSelectedRoutePart = atom<PicklistRoute>({
  key: getKey('picklist-route-part-selected'),
  default: null,
});

export const optimisationSelectedRoutePartZoom = selector<PicklistRoute>({
  key: getKey('picklist-route-part-selected-zoom'),
  get: ({ get }) => get(optimisationSelectedRoutePart),
  set: ({ get, set }, value: PicklistRoute) => {
    if (value) {
      const viewport =
        get(warehouseMeasurementSystem) === MeasurementSystem.METRIC
          ? 900
          : 900 * 0.4;
      const minX = _.min(value.waypoints.map(wp => wp.position.x));
      const maxX = _.max(value.waypoints.map(wp => wp.position.x));
      const minY = _.min(value.waypoints.map(wp => wp.position.y));
      const maxY = _.max(value.waypoints.map(wp => wp.position.y));

      set(stageStateById('simulation-area-view'), {
        ...get(stageStateById('simulation-area-view')),
        autoSizeId: nanoid(),
        contentBounds: [
          [minX - viewport, minY - viewport],
          [maxX + viewport, maxY + viewport],
        ],
      });
    }

    set(optimisationSelectedRoutePart, value);
  },
});

export const optimisationAssignmentDiff = atom<AssignmentDiff>({
  key: getKey('assignment-diff'),
  default: null,
});

export const optimisationAssignmentDiffLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('assignment-diff-load-status'),
  default: AsyncLoadStatus.None,
});

export const optimisationAssignmentDiffState = atom<
  DatasetTableState<OptimizationAssignmentDiffTableColumn>
>({
  key: getKey('assignment-diff-state'),
  default: {
    searchValues: {},
    sortValues: {
      category: SortDirection.DESC,
    },
  },
});

export const optimisationSelectedAssignmentDiff =
  atom<AssignmentDiffItemFragment>({
    key: getKey('assignment-selected-diff'),
    default: null,
  });

export const optimisationAssignmentImplement = atom<AssignmentDiffFull>({
  key: getKey('assignment-diff-implement'),
  default: null,
});

export const optimisationAssignmentImplementStatus = atom<AsyncLoadStatus>({
  key: getKey('assignment-implement-load-status'),
  default: AsyncLoadStatus.None,
});

export const optimisationAssignmentImplementFiltered =
  selector<AssignmentDiffFull>({
    key: getKey('assignment-diff-implement-filtered'),
    get: ({ get }) => {
      const full = get(optimisationAssignmentImplement);

      const filtered = getAssignmentDiffFiltered(full?.content);
      return {
        totalCount: full?.totalCount,
        content: filtered,
      };
    },
  });

export const optimisationStatus = selector<OptimizationRunStatus>({
  key: getKey('is-ready'),
  get: ({ get }) => {
    const optResultStatus = get(optimisationResultStatus);
    if (optResultStatus) {
      return get(optimisationResultStatus)?.status;
    }
    const sim = get(simulationCurrent);
    const optimisation = get(optimisationResult);

    const run = _.head(sim?.optimizationRuns?.content);
    return optimisation?.status ?? run?.status;
  },
});

export const optimisationIsEditable = selector<boolean>({
  key: getKey('is-editable'),
  get: ({ get }) => {
    const optimiseStatus = get(optimisationStatus);
    return (
      _.isNil(optimiseStatus) || optimiseStatus === OptimizationRunStatus.FAILED
    );
  },
});

export const optimisationIsFeatureSelected = atom<boolean>({
  key: getKey('is-optimisation-feature'),
  default: false,
});

export const optimizationSelectedMoveRoute = selector<
  LayoutPlaneRouteFragment[]
>({
  key: getKey('selected-move-route'),
  get: async ({ get }) => {
    const errorMsg = 'Can not build route between location';
    const simulationId = get(simulationCurrentId);
    const policy = get(routingPolicy);
    const selectedJob = get(analyzeJobDetailsSelectedRow);
    const agentId = get(simulationLayoutActiveAgentId);
    if (_.isNil(selectedJob) || _.isNil(agentId)) {
      return null;
    }

    try {
      const response = await secureClient.query<
        BuildSimulationLayoutRouteQuery,
        BuildSimulationLayoutRouteQueryVariables
      >({
        query: BuildSimulationLayoutRouteDocument,
        variables: {
          input: {
            simulationId,
            agentId,
            locations: [selectedJob.srcLocationId, selectedJob.destLocationId],
            routingPolicy: getRoutingPolicyInput(policy),
            includeTerminals: false,
            order: LayoutRouteOrder.KEEP_ORDER,
          },
        },
      });
      if (!_.isEmpty(response.errors)) {
        console.error(errorMsg, response.errors);
        throw new Error(errorMsg);
      }

      const routes = response.data?.buildSimulationLayoutRoute?.routes;
      return routes;
    } catch (ex) {
      console.error(errorMsg, ex);
      throw new Error(errorMsg);
    }
  },
});

export const optimisationUseAllocationRun = atom<boolean>({
  key: getKey('use-allocation-run'),
  default: false,
});
