import { signOut } from 'aws-amplify/auth';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { cn } from '../../common/utils';
import { MenuGlobal } from '../../components/MenuGlobal';
import { Button } from '../../components/actions/Button';
import * as Icon from '../../components/icons';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import PanelContainer from '../../containers/PanelContainer';
import StatManagementComponent from '../../feed/components/FeedFeatureManagement';
import FeedSettingsDataPanel from '../../feed/panels/FeedSettingsDataPanel';
import Callout from '../../helpContext/Callout';
import { appIsEmbeddedState } from '../../store/global.state';
import FeedGlobalFilterPanel from '../components/FeedGlobalFilterPanel';
import ProcessTypeFilters from '../components/ProcessTypeFilters';
import ResourceFilters from '../components/ResourceFilters';
import { actualitySelectedDocument } from '../store/actuality.state';
import ActualityPipelineSettings from './ActualityPipelineSettings';
import FeedExtraSettingsPanel from './FeedExtraSettingsPanel';
import FeedResourcePolicyPanel from './FeedResourcePolicyPanel';

const FeedSetupPanel: React.FC = () => {
  const { t } = useTranslation('feed');
  const logout = signOut;
  const isAppEmbedded = useRecoilValue(appIsEmbeddedState);
  const actuality = useRecoilValue(actualitySelectedDocument);
  const showFeedSetup = !actuality?.activityFeedId || !actuality?.layoutId;

  return (
    <div data-component="FeedSetupPanel" className={classNames('h-full')}>
      {isAppEmbedded && (
        <MenuGlobal
          className="bg-menu p-1"
          config={{
            setting: { show: true, showTitle: true },
            notifications: { show: false, showTitle: false },
            help: { show: true, showTitle: true },
            subscription: { show: false, showTitle: false },
          }}
        />
      )}

      <ScreenTitle
        title={t`Setup`}
        subtitle={t`Feed`}
        helpNavTo={'actuality/actuality-setup'}
        icon={Icon.Settings3}
      />

      {showFeedSetup ? (
        <PanelContainer
          id="panel-feed-settings-data"
          title={t`Data Settings`}
          collapsible
        >
          <FeedSettingsDataPanel />
        </PanelContainer>
      ) : (
        <>
          <PanelContainer
            id="panel-feed-settings-data"
            title={t`Data Settings`}
            collapsible
          >
            <FeedSettingsDataPanel />
          </PanelContainer>
          <PanelContainer
            id="panel-feed-settings-kpi-stats"
            title={t`Feature Visibility`}
            collapsible
          >
            <StatManagementComponent />
          </PanelContainer>

          {/* <PanelContainer
        id="panel-feed-settings-permissions"
        title={t`Permissions`}
        collapsible
      >
        <FeedFeaturesPermissionManagementComponent />
      </PanelContainer> */}

          <PanelContainer
            id="panel-feed-resource-policy"
            title={t`Resource Policy`}
            collapsible
          >
            <FeedResourcePolicyPanel />
          </PanelContainer>

          <ResourceFilters />

          <ProcessTypeFilters />

          <FeedGlobalFilterPanel />
          <FeedExtraSettingsPanel />
          <ActualityPipelineSettings />
        </>
      )}

      {isAppEmbedded && (
        <PanelContainer
          id="panel-logout"
          title={t`Logout`}
          collapsible
          hasPadding
        >
          <Callout type="suggestion">
            {t`Are you sure you want to logout?`}

            <Button
              className={cn(
                'backdrop-saturate-110 bg-opacity-80 p-2 text-start backdrop-blur-lg backdrop-filter',
                'rounded',
                'mt-4',
              )}
              buttonSize={`sm`}
              label={t`Logout`}
              full
              buttonType={`primary`}
              hasIconAfter={<Icon.ArrowRight className={cn('h-4 w-4')} />}
              onPress={() => logout()}
            />
          </Callout>
        </PanelContainer>
      )}
    </div>
  );
};

export default FeedSetupPanel;
