import {
  OptimizationRunStatus,
  OptimizationSettings,
  ReassignJobCycleMode,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import ErrorIndicator from '../../components/ErrorIndicator';
import DropdownSelector from '../../components/actions/DropdownSelector';
import { InputGroupList } from '../../components/inputs/InputGroupList';
import InputNumber from '../../components/inputs/InputNumber';
import SectionOptional from '../../components/layout/SectionOptional';
import useUpdateSimulation from '../hooks/useUpdateSimulation';
import { allocationRunSummary } from '../store/allocation/allocation.state';
import {
  optimisationStatus,
  optimisationUseAllocationRun,
} from '../store/optimisation.state';
import { optimizationSetupStepMultiplier } from '../store/optimisationSetup.state';
import { optimisationLimitSettings } from '../store/simulation.default';
import { simulationCurrent } from '../store/simulation.state';
import OptimisationTerminationSettings from './optimization/OptimisationTerminationSettings';

const OptimisationRunSettings: React.FC = () => {
  const { t } = useTranslation('simulation');
  const [sim, updateSim] = useRecoilState(simulationCurrent);
  const [stepMultiplier, setStepMultiplier] = useRecoilState(
    optimizationSetupStepMultiplier,
  );
  const updateSimulation = useUpdateSimulation();
  const optStatus = useRecoilValue(optimisationStatus);
  const [useAllocationRun, setUseAllocationRun] = useRecoilState(
    optimisationUseAllocationRun,
  );
  const allocationRun = useRecoilValue(allocationRunSummary);
  const hasOptimisationError = optStatus === OptimizationRunStatus.FAILED;
  const currentMaxMoves = sim.optimizationSettings?.limitSettings?.maxMoves;

  // Rating Scale
  // Exceptional (Slowest) – Excellent – Very Good – Good – Fair - Moderate – Slightly - Test (fastest)
  const optimisationOptions: Record<number, { title: string; desc: string }> = {
    0.25: {
      title: t`Quick Run Optimise`,
      desc: t`Test`,
    },
    0.5: {
      title: t`Slightly Optimise`,
      desc: t`(Fastest)`,
    },
    1: {
      title: t`Moderate Optimise`,
      desc: t`(Faster)`,
    },
    2: { title: t`Fair Optimise`, desc: t`(Fast)` },
    5: { title: t`Good`, desc: t`(Standard)` },
    10: {
      title: t`Very Good Optimise`,
      desc: t`(Slow)`,
    },
    25: {
      title: t`Excellent Optimise`,
      desc: t`(Slower)`,
    },
    50: {
      title: t`Exceptional Optimise`,
      desc: t`(Slowest)`,
    },
  };

  const multipliers: number[] = _(optimisationOptions)
    .keys()
    .map(v => parseFloat(v))
    .sortBy()
    .value();

  function updateOptimisationSettings(patch: Partial<OptimizationSettings>) {
    updateSim(current => ({
      ...current,
      optimizationSettings: {
        ...(current.optimizationSettings ?? {}),
        ...patch,
      },
    }));

    updateSimulation({
      optimizationSettings: patch,
    });
  }

  return (
    <>
      {hasOptimisationError && (
        <ErrorIndicator
          selfCenter
          message={[
            t`Optimisation run failed.` +
              ` ` +
              t`Check settings or contact support if the issue persists.`,
          ]}
        />
      )}

      <InputGroupList className="space-y-2 p-1 lg:p-2 xl:p-4">
        <h4 className="text-menu-active py-2 text-base">{t`Optimisation Settings`}</h4>
        <fieldset>
          <h4 className="text-menu-text mt-2 px-0.5 py-2 text-xs uppercase ltr:mr-1 rtl:ml-1">{t`Optimisation Strategy`}</h4>
          <DropdownSelector
            border
            DropAlignRight
            widthFull
            light
            multiline
            renderValue={i => optimisationOptions[i]?.title}
            values={multipliers}
            onChange={v => setStepMultiplier(v)}
            value={stepMultiplier}
            valueHelper={i => optimisationOptions[i]?.desc}
          />
        </fieldset>

        <OptimisationTerminationSettings
          setting={sim.optimizationSettings?.terminationSettings}
          onChange={terminationSettings =>
            updateOptimisationSettings({ terminationSettings })
          }
        >
          <SectionOptional
            id={'optimisation-limit-by-moves'}
            title={t`Max Moves`}
            value={!_.isNil(currentMaxMoves)}
            onChange={enabled =>
              updateOptimisationSettings({
                limitSettings: enabled
                  ? {
                      maxMoves: optimisationLimitSettings.maxMoves,
                    }
                  : null,
              })
            }
          >
            <InputNumber
              title={t`Max Items Moves`}
              value={currentMaxMoves}
              onChange={v =>
                updateOptimisationSettings({
                  limitSettings: {
                    maxMoves: v,
                  },
                })
              }
            />
          </SectionOptional>
        </OptimisationTerminationSettings>

        <SectionOptional
          id={'optimisation-location-size-constraints'}
          title={t`Location Size Constraints`}
          value={
            sim.optimizationSettings?.locationSizeConstraint?.disabled === false
          }
          onChange={enabled =>
            updateOptimisationSettings({
              locationSizeConstraint: {
                disabled: !enabled,
              },
            })
          }
        />
        <SectionOptional
          id={'optimisation-bay-width-constraints'}
          title={t`Bay Width Constraints`}
          value={
            sim.optimizationSettings?.bayWidthConstraint?.disabled === false
          }
          onChange={enabled =>
            updateOptimisationSettings({
              bayWidthConstraint: {
                disabled: !enabled,
              },
            })
          }
        />
        <SectionOptional
          id={'optimisation-reassign-jobs-constraints'}
          title={t`Include Reassign Cost`}
          value={
            sim.optimizationSettings?.reassignJobs?.disabled === false &&
            sim.optimizationSettings?.reassignJobs !== null
          }
          onChange={enabled =>
            updateOptimisationSettings({
              reassignJobs: {
                ...(sim.optimizationSettings?.reassignJobs ?? {}),
                disabled: !enabled,
              },
            })
          }
        />

        <SectionOptional
          id={'optimisation-reassign-jobs-cycles'}
          title={t`Expand reassign move cycles`}
          value={
            sim.optimizationSettings?.reassignJobs?.cycleMode ===
            ReassignJobCycleMode.STASH
          }
          onChange={enabled =>
            updateOptimisationSettings({
              reassignJobs: {
                ...(sim.optimizationSettings?.reassignJobs ?? {}),
                cycleMode: enabled
                  ? ReassignJobCycleMode.STASH
                  : ReassignJobCycleMode.EXCHANGE,
              },
            })
          }
        />
        <SectionOptional
          id={'optimisation-pickability-constraint'}
          title={t`Allow swap between rules`}
          value={
            !sim.optimizationSettings?.pickabilityConstraint?.disabled === false
          }
          onChange={allowSwap =>
            updateOptimisationSettings({
              pickabilityConstraint: {
                disabled: allowSwap,
              },
            })
          }
        />
        <SectionOptional
          id={'optimisation-capacity-constraints'}
          title={t`Consolidate items`}
          value={
            !sim.optimizationSettings?.assignmentCapacitySettings
              ?.avoidMerge === true
          }
          onChange={enabled =>
            updateOptimisationSettings({
              assignmentCapacitySettings: {
                avoidMerge: !enabled,
              },
            })
          }
        />
        {allocationRun && (
          <SectionOptional
            id={'optimisation-use-allocation-run'}
            title={t`Use allocation`}
            value={useAllocationRun}
            onChange={value => setUseAllocationRun(value)}
          />
        )}
      </InputGroupList>
    </>
  );
};
export default OptimisationRunSettings;
