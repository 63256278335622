import { useUpdateActualityMutation } from '@warebee/frontend/data-access-api-graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import InputNumber from '../../components/inputs/InputNumber';
import SectionOptional from '../../components/layout/SectionOptional';
import PanelContainer from '../../containers/PanelContainer';
import {
  actualityExtraSettings,
  actualitySelectedId,
} from '../store/actuality.state';
import { ActualityExtraSettings } from '../store/actuality.types';

const FeedExtraSettingsPanel: React.FC = () => {
  const { t } = useTranslation('feed');
  const actualityId = useRecoilValue(actualitySelectedId);
  const [extraSettings, setExtraSettings] = useRecoilState(
    actualityExtraSettings,
  );
  const [callUpdateActuality] = useUpdateActualityMutation();

  function updateExtraSettings(patch: Partial<ActualityExtraSettings>) {
    const newSettings = {
      ...extraSettings,
      ...patch,
    };
    setExtraSettings(newSettings);
    callUpdateActuality({
      variables: {
        input: {
          actualityId,
          extraSettings: newSettings,
        },
      },
    });
  }

  return (
    <PanelContainer
      id="panel-feed-global-update-settings"
      title={t`Refresh Settings`}
      collapsible
      hasPadding
    >
      {/* <InputGroupList className="space-y-2 p-1 lg:p-2 xl:p-4"> */}
      <SectionOptional
        id={'allocation-extra-settings-interval'}
        title={t`Refresh interval`}
        value={true}
        disabled
      >
        <InputNumber
          title={t`Check interval (min)`}
          value={extraSettings?.refreshInterval ?? 5}
          range={[0, 1000]}
          onChange={v =>
            updateExtraSettings({
              refreshInterval: v,
            })
          }
        />
      </SectionOptional>
    </PanelContainer>
  );
};

export default FeedExtraSettingsPanel;
