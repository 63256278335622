import {
  AnalyzePicklistEventsFragment,
  AnalyzeResultAgentSummary,
  AnalyzeResultFragment,
  AnalyzeResultOrderDetailsFragment,
  AnalyzeResultOrderSummaryFragment,
  AnalyzeResultStatus,
  AnalyzeResultWaypointFragment,
  SimulationItemSetFragment,
  SimulationMetaFragment,
  SimulationOrderLineSetFragment,
} from '@warebee/frontend/data-access-api-graphql';
import { endOfDay, startOfDay } from 'date-fns';
import { t } from 'i18next';
import _ from 'lodash';
import { atom, atomFamily, selector } from 'recoil';
import { PanelFixedHeight } from '../../common/component.types';
import { toDateFromLocaleStringDate } from '../../common/dateTimeHelper';
import { persistAtom } from '../../common/recoil/persistAtom';
import { AsyncLoadStatus } from '../../common/types';
import { viewerSelectedPlaneId } from '../../layout/viewer/store/viewer.state';
import { UomSpec } from '../data/palletSpecsHelper';
import {
  allocationAnalyzeResult,
  allocationAssignmentId,
  allocationRunSummary,
} from './allocation/allocation.state';
import {
  optimisationAnalyzeResult,
  optimisationResult,
} from './optimisation.state';
import { routingPolicySelectedIdentity } from './routingPolicy/routingPolicy.state';
import { loadWaypointHeatmap } from './simulation.helper';
import {
  simulationLayoutActiveAgentId,
  simulationLayoutSelectedPicklistId,
  simulationLayoutShowCongestion,
} from './simulation.layout.state';
import {
  AnalyzeOrdersListState,
  AnalyzeResultCompareType,
  AnalyzeResultType,
  DataSetTableType,
  RoutingDisplayMode,
} from './simulation.types';
import { simulationWizardSelectedStepId } from './simulation.wizard.state';

const getKey = (postfix: string) => `warebee-simulation-${postfix}`;

export const simulationCurrent = atom<SimulationMetaFragment>({
  key: getKey('current'),
  default: null,
});

export const simulationUpdateStatus = atom<AsyncLoadStatus>({
  key: getKey('update-status'),
  default: AsyncLoadStatus.None,
});

export const simulationCurrentId = selector<string>({
  key: getKey('current-id'),
  get: ({ get }) => {
    const simulation = get(simulationCurrent);
    return simulation?.id;
  },
});

export const simulationUoms = atom<string[]>({
  key: getKey('uoms-list'),
  default: [],
});

export const simulationLayoutId = selector<string>({
  key: getKey('layout-id'),
  get: ({ get }) => {
    const simulation = get(simulationCurrent);
    return simulation?.layout?.id;
  },
});

export const simulationAssignmentId = selector<string>({
  key: getKey('assignment-id'),
  get: ({ get }) => {
    const simulation = get(simulationCurrent);
    return simulation?.assignment?.id;
  },
});

export const simulationItemSetId = selector<string>({
  key: getKey('item-set-id'),
  get: ({ get }) => {
    const simulation = get(simulationCurrent);
    return simulation?.itemSet?.id;
  },
});

export const simulationOrderSetId = selector<string>({
  key: getKey('order-set-id'),
  get: ({ get }) => {
    const simulation = get(simulationCurrent);
    return simulation?.orderSet?.id;
  },
});

// --------------- ANALYZE -----------------

export const simulationAnalyzeTypeSelectedAtom = atom<AnalyzeResultType>({
  key: getKey('analyze-type-selected-atom'),
  default: 'initial',
});

export const simulationAnalyzeTypeSelected = selector<AnalyzeResultType>({
  key: getKey('analyze-type-selected'),
  get: ({ get }) => get(simulationAnalyzeTypeSelectedAtom),
  set: ({ get, set }, value) => {
    set(simulationAnalyzeTypeSelectedAtom, value);

    switch (value) {
      case 'initial':
        set(simulationEffectiveAssignmentId, get(simulationAssignmentId));
        break;
      case 'allocate':
        set(simulationEffectiveAssignmentId, get(allocationAssignmentId));
        break;
    }
  },
});

export const simulationAnalyzeResultAtom = atom<AnalyzeResultFragment>({
  key: getKey('analyze-result-atom'),
  default: null,
});

export const simulationAnalyzeResult = selector<AnalyzeResultFragment>({
  key: getKey('analyze-result'),
  get: ({ get }) => {
    const analyzeType = get(simulationAnalyzeTypeSelected);
    switch (analyzeType) {
      case 'initial':
        return get(simulationAnalyzeResultAtom);
      case 'allocate':
        return get(allocationAnalyzeResult);
    }
  },
});

export const simulationAnalyzeId = selector<string>({
  key: getKey('analyze-id'),
  get: ({ get }) => get(simulationAnalyzeResult)?.id,
});

export const simulationMaxLocationVisits = selector<number>({
  key: getKey('max-location-visits'),
  get: ({ get }) => {
    const analyzeResult = get(simulationAnalyzeResult);
    const optimizedAnalyzeResult = get(optimisationAnalyzeResult);
    return Math.max(
      analyzeResult?.maxLocationHitCount ?? 0,
      optimizedAnalyzeResult?.maxLocationHitCount ?? 0,
    );
  },
});

export const simulationMaxTriggeredReorders = selector<number>({
  key: getKey('max-triggered-reorders'),
  get: ({ get }) => {
    const analyzeResult = get(simulationAnalyzeResult);
    const optimizedAnalyzeResult = get(optimisationAnalyzeResult);
    return Math.max(
      analyzeResult?.maxReorderTriggeredSummary?.count ?? 0,
      optimizedAnalyzeResult?.maxReorderTriggeredSummary?.count ?? 0,
    );
  },
});

export const simulationMaxAppliedReorders = selector<number>({
  key: getKey('max-applied-reorders'),
  get: ({ get }) => {
    const analyzeResult = get(simulationAnalyzeResult);
    const optimizedAnalyzeResult = get(optimisationAnalyzeResult);
    return Math.max(
      analyzeResult?.maxReorderAppliedSummary?.count ?? 0,
      optimizedAnalyzeResult?.maxReorderAppliedSummary?.count ?? 0,
    );
  },
});

export const simulationWaypointHeatmap = selector<
  AnalyzeResultWaypointFragment[]
>({
  key: getKey('locations-waypoints-heatmap'),
  get: async ({ get }) => {
    const analyzeId = get(simulationAnalyzeId);
    const areaId = get(viewerSelectedPlaneId);
    const showCongestion = get(simulationLayoutShowCongestion);

    if (!showCongestion) return null;

    return await loadWaypointHeatmap({ analyzeId, areaId });
  },
});

export const simulationLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('-load-status'),
  default: AsyncLoadStatus.None,
});

export const simulationAnalyzeStatus = selector<AnalyzeResultStatus>({
  key: getKey('is-ready'),
  get: ({ get }) => {
    const sim = get(simulationCurrent);
    const analyze = get(simulationAnalyzeResult);
    return analyze?.status ?? sim?.analyzeResult?.status;
  },
});

export const simulationIsEditable = selector<boolean>({
  key: getKey('can-change-dataset'),
  get: ({ get }) => {
    const analyzeStatus = get(simulationAnalyzeStatus);
    return (
      analyzeStatus !== AnalyzeResultStatus.COMPLETED &&
      analyzeStatus !== AnalyzeResultStatus.CREATED &&
      analyzeStatus !== AnalyzeResultStatus.ANALYZING
    );
  },
});

export const simulationAnalyzeOrdersListData = atom<
  AnalyzeResultOrderSummaryFragment[]
>({
  key: getKey('analyze-orders-list-data'),
  default: [],
});

export const simulationAnalyzeOrdersListStatus = atom<AsyncLoadStatus>({
  key: getKey('analyze-orders-list-load-status'),
  default: AsyncLoadStatus.None,
});

export const simulationAnalyzeOrdersListState = atom<AnalyzeOrdersListState>({
  key: getKey('analyze-orders-list-state'),
  default: {
    searchValues: {},
    sortBy: 'cost',
    totalCount: 0,
  },
});

export const simulationAnalyzeOrderDetails =
  atom<AnalyzeResultOrderDetailsFragment>({
    key: getKey('analyze-order-details'),
    default: null,
  });

export const simulationAnalyzeOrderDetailsLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('analyze-order-details-load-status'),
  default: AsyncLoadStatus.None,
});

export const policyHighlightedLocations = atom<Set<string>>({
  key: getKey('highlighted-locations'),
  default: null,
});

export const policyHighlightedLocationsLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('highlighted-locations-status'),
  default: AsyncLoadStatus.None,
});

export const simulationShowDatasetAsTable = atom<DataSetTableType>({
  key: getKey('show-dataset-as-table'),
  default: null,
});

export const simulationExtraDataViewHeight = atom<PanelFixedHeight>({
  key: getKey('extra-dataset-height'),
  default: 'h-default',
});

export const simulationOrderLineSet = atom<SimulationOrderLineSetFragment>({
  key: getKey('order-line-set'),
  default: null,
});

export const simulationOrderLineSetLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('order-line-set-status'),
  default: AsyncLoadStatus.None,
});

export const simulationEffectiveItemSet = atom<SimulationItemSetFragment>({
  key: getKey('effective-item-set'),
  default: null,
});

export const simulationEffectiveItemSetLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('effective-item-set-load-status'),
  default: AsyncLoadStatus.None,
});

export const simulationAnalyzeDistinctDays = selector<number>({
  key: getKey('analyze-distinct-days'),
  get: ({ get }) =>
    _(get(simulationAnalyzeResult)?.picklistPickByDates)
      .compact()
      .uniq()
      .size(),
});

export const simulationAnalyzeInterval = selector<[Date, Date]>({
  key: getKey('analyze-interval'),
  get: ({ get }) => {
    const raw = _(get(simulationAnalyzeResult)?.picklistPickByDates)
      .compact()
      .uniq()
      .map(toDateFromLocaleStringDate)
      .value();
    return [startOfDay(_.min(raw)), endOfDay(_.max(raw))];
  },
});

export const simulationAnalyzeAgentEventsMap = selector<
  Record<string, AnalyzeResultAgentSummary>
>({
  key: getKey('agent-events-map'),
  get: ({ get }) => {
    return _.keyBy(get(simulationAnalyzeResult)?.summary?.agents, a => a.agent);
  },
});

export const simulationAnalyzePicklistEventsLoadStatus = atomFamily<
  AsyncLoadStatus,
  string
>({
  key: getKey('picklist-events-load-status'),
  default: () => AsyncLoadStatus.None,
});

export const simulationAnalyzePicklistEventsSelectedLoadStatus =
  selector<AsyncLoadStatus>({
    key: getKey('picklist-events-load-status-selected'),
    get: ({ get }) => {
      const picklistId = get(simulationLayoutSelectedPicklistId);
      let status = AsyncLoadStatus.None;
      if (!_.isNil(picklistId)) {
        status =
          get(simulationAnalyzePicklistEventsLoadStatus(picklistId)) ??
          AsyncLoadStatus.None;
      }
      return status;
    },
  });

export const simulationAnalyzePicklistEvents = atomFamily<
  AnalyzePicklistEventsFragment,
  string
>({
  key: getKey('picklist-events'),
  default: () => null,
});

export const simulationRoutingDisplayMode = selector<RoutingDisplayMode>({
  key: getKey('routing-display-mode'),
  get: ({ get }) => {
    const stepId = get(simulationWizardSelectedStepId);
    const agent = get(simulationLayoutActiveAgentId);
    if (_.isNil(agent)) return 'none';
    const isEditable = get(simulationIsEditable);
    const isPolicyActive = get(
      routingPolicySelectedIdentity,
    )?.isRoutingConfigurationSelected;
    switch (stepId) {
      case 'policy-routing':
        return isEditable && isPolicyActive ? 'active' : 'restricted';
      case 'analyse':
      case 'optimise':
      case 'optimise-reassign':
        return 'restricted';
    }
    return 'none';
  },
});

export const simulationAnalyzeCompareTypeSelected =
  atom<AnalyzeResultCompareType>({
    key: getKey('analyze-compare-type-selected'),
    default: 'compare',
  });

export const simulationShowComplianceTable = atom<boolean>({
  key: getKey('show-compliance-table'),
  default: false,
});

export const heatmapLegendSortOrder = atom<'asc' | 'desc'>({
  key: getKey('heatmapLegendSortOrder'),
  default: 'asc',
});

export const selectedPalletSpecState = atom<UomSpec>({
  key: getKey('selectedPalletSpec'),
  // default: 'pallet',
});

export const simulationWithAllocation = persistAtom<boolean>({
  key: getKey('with-allocation'),
  default: false,
});

export const simulationAvailableAssignments = selector<Record<string, string>>({
  key: getKey('available-assignments'),
  get: ({ get }) => {
    const assignments = {
      [get(simulationAssignmentId)]: t`Actual`,
    };
    const allocationRun = get(allocationRunSummary);
    if (!_.isNil(allocationRun?.resultAssignment)) {
      assignments[allocationRun.resultAssignment.id] = t`Allocation`;
    }
    const optimisationRun = get(optimisationResult);
    if (!_.isNil(optimisationRun?.resultAssignment)) {
      assignments[optimisationRun.resultAssignment.id] = t`Optimisation`;
    }
    return assignments;
  },
});

export const simulationAvailableAnalyzeIds = selector<Record<string, string>>({
  key: getKey('available-analyze-ids'),
  get: ({ get }) => {
    const analyzeIds = {
      [get(simulationAnalyzeId)]: t`Actual`,
    };
    const allocationRun = get(allocationRunSummary);
    if (!_.isNil(allocationRun?.analyzeResult.id)) {
      analyzeIds[allocationRun.analyzeResult.id] = t`Allocation`;
    }
    const optimisationRun = get(optimisationResult);
    if (!_.isNil(optimisationRun?.analyzeResult.id)) {
      analyzeIds[optimisationRun.analyzeResult.id] = t`Optimisation`;
    }
    return analyzeIds;
  },
});

export const simulationEffectiveAssignmentIdAtom = atom<string>({
  key: getKey('selected-assignment-id-atom'),
  default: null,
});

export const simulationEffectiveAssignmentId = selector<string>({
  key: getKey('selected-assignment-id'),
  get: ({ get }) => {
    return (
      get(simulationEffectiveAssignmentIdAtom) ?? get(simulationAssignmentId)
    );
  },
  set: ({ set }, value) => set(simulationEffectiveAssignmentIdAtom, value),
});

export const viewAs = atom<string>({
  key: getKey('view-as'),
  default: 'Layout',
});

export const viewAsSelector = selector<string>({
  key: getKey('view-as-selector'),
  get: ({ get }) => {
    const showComplianceTable = get(simulationShowComplianceTable);
    return showComplianceTable ? 'Compliance' : get(viewAs);
  },
  set: ({ set }, newValue) => {
    set(viewAs, newValue);
  },
});
