import {
  Field,
  ID,
  InputAndObjectType,
  registerEnumType,
} from '@warebee/shared/util-backend-only-types';
import {
  AssignmentPolicyConstraintSettings,
  BayWidthConstraintSettings,
  LocationSizeConstraintSettings,
} from '../constraint.model';
import { OrderSetFilter } from '../dataset-filters.model';
import { LocationFilterUnion, SimulationItemFilterUnion } from '../policies';

export enum AllocationEnforcePickability {
  FIRST = 'FIRST',
  LAST = 'LAST',
  NEVER = 'NEVER',
}

registerEnumType(AllocationEnforcePickability, {
  name: 'AllocationEnforcePickability',
});

@InputAndObjectType()
export class AllocationPickabilitySettings {
  @Field(() => AllocationEnforcePickability, {
    nullable: true,
    defaultValue: AllocationEnforcePickability.FIRST,
  })
  enforcePickability?: AllocationEnforcePickability;
}

export enum AllocationItemPriority {
  // order set item properties
  ORDER_LINE_COUNT = 'ORDER_LINE_COUNT',

  TOTAL_DAYS_WITH_ORDERS = 'TOTAL_DAYS_WITH_ORDERS',
  TOTAL_WEEKS_WITH_ORDERS = 'TOTAL_WEEKS_WITH_ORDERS',

  DAILY_ORDER_LINE_COUNT_AVG = 'DAILY_ORDER_LINE_COUNT_AVG',
  DAILY_ORDER_LINE_COUNT_MAX = 'DAILY_ORDER_LINE_COUNT_MAX',
  DAILY_ORDER_LINE_COUNT_CV = 'DAILY_ORDER_LINE_COUNT_CV',

  WEEKLY_ORDER_LINE_COUNT_AVG = 'WEEKLY_ORDER_LINE_COUNT_AVG',
  WEEKLY_ORDER_LINE_COUNT_MAX = 'WEEKLY_ORDER_LINE_COUNT_MAX',
  WEEKLY_ORDER_LINE_COUNT_CV = 'WEEKLY_ORDER_LINE_COUNT_CV',

  WEEKLY_DAYS_WITH_ORDERS_AVG = 'WEEKLY_DAYS_WITH_ORDERS_AVG',
  WEEKLY_DAYS_WITH_ORDERS_MAX = 'WEEKLY_DAYS_WITH_ORDERS_MAX',
  WEEKLY_DAYS_WITH_ORDERS_CV = 'WEEKLY_DAYS_WITH_ORDERS_CV',

  // allocation req properties
  ESTIMATED_REPLENISHMENT_COUNT = 'ESTIMATED_REPLENISHMENT_COUNT',
  REMAINING_REQUIRED_VOLUME = 'REMAINING_REQUIRED_VOLUME',

  // item properties
  SKU_GROUP = 'SKU_GROUP',
  SUBGROUP = 'SUBGROUP',
  TRANSPORT_CLASS = 'TRANSPORT_CLASS',
  STOCK_CATEGORY = 'STOCK_CATEGORY',
  STORAGE_CLASS = 'STORAGE_CLASS',
  POLLUTION_CLASS = 'POLLUTION_CLASS',

  // uom properties
  UOM_HEIGHT = 'UOM_HEIGHT',
  UOM_WIDTH = 'UOM_WIDTH',
  UOM_LENGTH = 'UOM_LENGTH',
  UOM_VOLUME = 'UOM_VOLUME',
}

registerEnumType(AllocationItemPriority, {
  name: 'AllocationItemPriority',
});

export enum AllocationLocationPriority {
  DISTANCE_FROM_START = 'DISTANCE_FROM_START',
  DISTANCE_TO_END = 'DISTANCE_TO_END',
  LOCATION_ORDER = 'LOCATION_ORDER',
  LOCATION_VOLUME = 'LOCATION_VOLUME',
  LOCATION_LEVEL = 'LOCATION_LEVEL',
  LOCATION_WIDTH = 'LOCATION_WIDTH',
  LOCATION_HEIGHT = 'LOCATION_HEIGHT',
  LOCATION_LENGTH = 'LOCATION_LENGTH',
  BAY_WIDTH = 'BAY_WIDTH',
  BAY_TITLE = 'BAY_TITLE',
}

registerEnumType(AllocationLocationPriority, {
  name: 'AllocationLocationPriority',
});

export enum AllocationPriorityDirection {
  DEFAULT = 'DEFAULT',
  ASC = 'ASC',
  DESC = 'DESC',
}

registerEnumType(AllocationPriorityDirection, {
  name: 'AllocationPriorityDirection',
});

@InputAndObjectType()
export class AllocationItemPrioritySpec {
  @Field(() => AllocationItemPriority)
  priority: AllocationItemPriority;

  @Field(() => AllocationPriorityDirection, {
    nullable: true,
    defaultValue: AllocationPriorityDirection.DEFAULT,
  })
  direction?: AllocationPriorityDirection;
}

@InputAndObjectType()
export class AllocationLocationPrioritySpec {
  @Field(() => AllocationLocationPriority)
  priority: AllocationLocationPriority;

  @Field(() => AllocationPriorityDirection, {
    nullable: true,
    defaultValue: AllocationPriorityDirection.DEFAULT,
  })
  direction?: AllocationPriorityDirection;
}

@InputAndObjectType()
export class AllocationPrioritySettings {
  @Field(() => [AllocationItemPrioritySpec], {
    nullable: true,
    defaultValue: [{ priority: AllocationItemPriority.ORDER_LINE_COUNT }],
  })
  itemPriority?: AllocationItemPrioritySpec[];

  @Field(() => [AllocationLocationPrioritySpec], {
    nullable: true,
    defaultValue: [
      { priority: AllocationLocationPriority.DISTANCE_FROM_START },
    ],
  })
  locationPriority?: AllocationLocationPrioritySpec[];
}

export enum AllocationAllowLocationReuse {
  ALWAYS = 'ALWAYS',
  FOR_PICKABILITY = 'FOR_PICKABILITY',
  NEVER = 'NEVER',
}

registerEnumType(AllocationAllowLocationReuse, {
  name: 'AllocationAllowReplacement',
});

@InputAndObjectType()
export class AllocationReplacementSettings {
  @Field(() => AllocationAllowLocationReuse, {
    nullable: true,
    defaultValue: AllocationAllowLocationReuse.FOR_PICKABILITY,
  })
  allow?: AllocationAllowLocationReuse;
}

@InputAndObjectType()
export class AllocationMultiplexingSettings {
  @Field(() => AllocationAllowLocationReuse, {
    nullable: true,
    defaultValue: AllocationAllowLocationReuse.FOR_PICKABILITY,
  })
  allow?: AllocationAllowLocationReuse;
}

export enum AllocationLimitApplyTo {
  REQUIREMENT = 'REQUIREMENT',
  RULE = 'RULE',
}

registerEnumType(AllocationLimitApplyTo, {
  name: 'AllocationLimitApplyTo',
});

@InputAndObjectType()
export class AllocationLimitSettings {
  @Field(() => AllocationLimitApplyTo, { nullable: true })
  applyTo?: AllocationLimitApplyTo;

  /**
   * @TJS-type  integer
   */
  @Field({ nullable: true })
  maxPickableAssignments?: number;

  /**
   * @TJS-type  integer
   */
  @Field({ nullable: true })
  maxAllocatedAssignments?: number;

  @Field({ nullable: true })
  maxPickableVolume?: number;

  @Field({ nullable: true })
  maxAllocatedVolume?: number;
}

@InputAndObjectType()
export class AllocationRoundSettings {
  /**
   * @TJS-type  integer
   */
  @Field({ nullable: true, defaultValue: 1 })
  maxAssignmentsPerRound?: number;
}

export enum AllocationRequirementAggregationMethod {
  DAILY_AVERAGE = 'DAILY_AVERAGE',
}

registerEnumType(AllocationRequirementAggregationMethod, {
  name: 'AllocationRequirementAggregationMethod',
});

@InputAndObjectType()
export class AllocationRequirementAggregationSettings {
  @Field(() => AllocationRequirementAggregationMethod, { nullable: true })
  aggregation?: AllocationRequirementAggregationMethod;

  @Field({ nullable: true })
  numberOfPeriods?: number;
}

@InputAndObjectType()
export class AllocationConstraintSettings {
  @Field(() => AssignmentPolicyConstraintSettings, { nullable: true })
  assignmentPolicyConstraint?: AssignmentPolicyConstraintSettings;

  @Field(() => LocationSizeConstraintSettings, { nullable: true })
  locationSizeConstraint?: LocationSizeConstraintSettings;

  @Field(() => BayWidthConstraintSettings, { nullable: true })
  bayWidthConstraint?: BayWidthConstraintSettings;
}

@InputAndObjectType()
export class AllocationRule {
  @Field(() => ID)
  id: string;

  @Field({ nullable: true })
  title?: string;

  // filter
  @Field(() => [String], { nullable: true })
  uomTypes?: string[];

  @Field(() => SimulationItemFilterUnion, { nullable: true })
  itemsMatch?: SimulationItemFilterUnion;

  @Field(() => LocationFilterUnion, { nullable: true })
  locationsMatch?: LocationFilterUnion;

  // settings
  @Field(() => AllocationRequirementAggregationSettings, { nullable: true })
  requirementAggregationSettings?: AllocationRequirementAggregationSettings;

  @Field(() => AllocationLimitSettings, { nullable: true })
  limitSettings?: AllocationLimitSettings;

  @Field(() => AllocationRoundSettings, { nullable: true })
  roundSettings?: AllocationRoundSettings;

  @Field(() => AllocationPickabilitySettings, { nullable: true })
  pickabilitySettings?: AllocationPickabilitySettings;

  @Field(() => AllocationPrioritySettings, { nullable: true })
  prioritySettings?: AllocationPrioritySettings;

  @Field(() => AllocationReplacementSettings, { nullable: true })
  replacementSettings?: AllocationReplacementSettings;

  @Field(() => AllocationMultiplexingSettings, { nullable: true })
  multiplexingSettings?: AllocationMultiplexingSettings;

  @Field(() => AllocationConstraintSettings, { nullable: true })
  constraintSettings?: AllocationConstraintSettings;
}

@InputAndObjectType()
export class AllocationPolicy {
  @Field(() => [AllocationRule], { nullable: true })
  rules?: AllocationRule[];

  @Field(() => AllocationRule, { nullable: true })
  defaultRule?: AllocationRule;
}

export enum DeallocateType {
  NONE = 'NONE',
  ALL = 'ALL',
  NON_COMPLIANT = 'NON_COMPLIANT',
}

registerEnumType(DeallocateType, {
  name: 'DeallocateType',
});

export enum ReallocateType {
  NONE = 'NONE',
  REALLOCATE = 'REALLOCATE',
}

registerEnumType(ReallocateType, {
  name: 'ReallocateType',
});

export enum ReallocateUomSource {
  CAPACITY_UOM = 'CAPACITY_UOM',
  STOCK_UOM = 'STOCK_UOM',
  FIXED_UOM = 'FIXED_UOM',
}

registerEnumType(ReallocateUomSource, {
  name: 'ReallocateUomSource',
});

export enum ReallocateQuantitySource {
  BY_CAPACITY = 'CAPACITY',
  STOCK_UOM_QUANTITY = 'STOCK_UOM_QUANTITY',
  FIXED_QUANTITY = 'FIXED_QUANTITY',
}

registerEnumType(ReallocateQuantitySource, {
  name: 'ReallocateQuantitySource',
});

@InputAndObjectType()
export class ReallocateQuantitySettings {
  @Field(() => ReallocateUomSource, {
    nullable: true,
    defaultValue: ReallocateUomSource.CAPACITY_UOM,
  })
  reallocateUom?: ReallocateUomSource;

  @Field(() => ReallocateQuantitySource, {
    nullable: true,
    defaultValue: ReallocateQuantitySource.BY_CAPACITY,
  })
  reallocateQuantity?: ReallocateQuantitySource;

  @Field(() => ID, { nullable: true })
  fixedUom?: string;

  /**
   * @TJS-type  integer
   */
  @Field({ nullable: true })
  fixedUomQuantity?: number;
}

@InputAndObjectType()
export class DeallocationRule {
  @Field(() => ID, { nullable: true })
  id?: string;

  @Field({ nullable: true })
  title?: string;

  @Field(() => SimulationItemFilterUnion, { nullable: true })
  itemsMatch?: SimulationItemFilterUnion;

  @Field(() => LocationFilterUnion, { nullable: true })
  locationsMatch?: LocationFilterUnion;

  @Field(() => AllocationConstraintSettings, { nullable: true })
  constraintSettings?: AllocationConstraintSettings;

  @Field(() => DeallocateType, { nullable: true })
  deallocateType?: DeallocateType;

  @Field(() => ReallocateType, { nullable: true })
  reallocateType?: ReallocateType;

  @Field(() => ReallocateQuantitySettings, { nullable: true })
  reallocateQuantitySettings?: ReallocateQuantitySettings;

  // @Field({ nullable: true })
  // disableAllocation?: boolean;
}

@InputAndObjectType()
export class DeallocationPolicy {
  @Field(() => [DeallocationRule], { nullable: true })
  rules?: DeallocationRule[];

  @Field(() => DeallocationRule, { nullable: true })
  defaultRule?: DeallocationRule;
}

@InputAndObjectType()
export class AllocationRequirementSourceSettings {
  @Field({ nullable: true })
  useOrderSetDemand?: boolean;
}

@InputAndObjectType()
export class AllocationSettings {
  @Field(() => OrderSetFilter, { nullable: true })
  orderSetFilter?: OrderSetFilter;

  @Field(() => AllocationRequirementSourceSettings, { nullable: true })
  requirementSource?: AllocationRequirementSourceSettings;

  @Field(() => AllocationPolicy, { nullable: true })
  allocationPolicy?: AllocationPolicy;

  @Field(() => DeallocationPolicy, { nullable: true })
  deallocationPolicy?: DeallocationPolicy;
}

export enum AllocationRunType {
  DRAFT = 'DRAFT',
  FULL = 'FULL',
}

registerEnumType(AllocationRunType, {
  name: 'AllocationType',
});
