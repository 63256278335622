import { CreateLayoutImportLocationInput } from '@warebee/shared/data-access-api-dto';
import { LAYOUT_IMPORT_MAPPING_SCHEMA } from '@warebee/shared/import-converter';
import { TFunction } from 'i18next';
import { keyBy } from 'lodash';
import { MappingSettings } from '../../store/import.types';

export function getLayoutSchema(
  t: TFunction<'importer'>,
): MappingSettings<CreateLayoutImportLocationInput> {
  const baseFields = keyBy(LAYOUT_IMPORT_MAPPING_SCHEMA.fields, f => f.name);

  return {
    fields: [
      {
        ...baseFields['locationId'],
        title: t('Location ID', { ns: 'importer' }),
        description: t('Location Name, typically in the format AA010101', {
          ns: 'importer',
        }),
        example: 'AA010101',
        aliases: [
          'locationId',
          'locationname',
          'location',
          'locid',
          'loc',
          'srcloc',
          'src',
          'dstloc',
          'source',
          'containerid',
          'container',
          'binid',
          'bin',
          'pickface',
          'pickfaceid',
          'pickfacelocationid',
          'rakusr',
          'blbinl',
          'WHSE_SLT_LOC_ID',
        ],
      },
      {
        ...baseFields['locationOrder'],
        title: t('Location Pick Order', { ns: 'importer' }),
        description: t(
          'Location picking order (picking path sequence). NUMBERS ONLY!',
          { ns: 'importer' },
        ),
        example: '1',
        aliases: [
          'locationorder',
          'order',
          'sortorder',
          'picksortorder',
          'pickorder',
          'pickingorder',
          'locsortorder',
          'locationsortorder',
          'picksequence',
          'pickingsequence',
          'sortsequence',
          'locationsequence',
          'locationpickorder',
          'pick_sequence_sku',
          'picksequencesku',
          'picking_seq',
          'pick_seq',
          'seq',
          'sequence',
          'blwalk',
          'trvseq',
        ],
      },
      {
        ...baseFields['locationStatus'],
        title: t('Status', { ns: 'importer' }),
        description: t('Active Locations vs. Non-Active as (True/False)', {
          ns: 'importer',
        }),
        example: 'True',
        valueResolver: baseFields['locationStatus'].defaultValueResolver,
        aliases: [
          'locationstatus',
          'locstatus',
          'status',
          'statuslocation',
          'statusloc',
          'active',
          'disabled',
          'inactive',
          'useable',
          'storeable',
          'pickable',
          'sts',
          'blsts',
          'location_sts',
        ],
      },
      {
        ...baseFields['locationLevel'],
        title: t('Level', { ns: 'importer' }),
        description: t('Level. Floor location has Level = 1.', {
          ns: 'importer',
        }),
        example: '1',
        aliases: [
          'locationLevel',
          'level',
          'loclevel',
          'levelloc',
          'levelLocation',
          'loclvl',
          'lvl',
        ],
      },
      {
        ...baseFields['warehouseArea'],
        title: t('Area', { ns: 'importer' }),
        description: t(
          'Warehouse Area. Multiple areas supported, also sometimes called Zones',
          { ns: 'importer' },
        ),
        example: 'Area X',
        aliases: [
          'warehousearea',
          'area',
          'zone',
          'warehousezone',
          'zonewarehouse',
          'areawarehouse',
          'sectorwarehouse',
          'sector',
          'segment',
          'block',
        ],
      },
      {
        ...baseFields['locationLength'],
        title: t('Location Depth (Length)', { ns: 'importer' }),
        description: t('Depth or Length of the location.', { ns: 'importer' }),
        example: '1200',
        aliases: [
          'locationlength',
          'locationLength_mm',
          'locationLength_cm',
          'locationLength_m',
          'locationLength_ft',
          'locationLength_in',
          'locationLength_inch',
          'locationlength(mm)',
          'locationlength(cm)',
          'locationlength(m)',
          'locationlength(ft)',
          'locationlength(in)',
          'locationlength(inch)',
          'locationdepth',
          'locationdepth(mm)',
          'locationdepth(cm)',
          'locationdepth(m)',
          'locationdepth(ft)',
          'locationdepth(in)',
          'locationdepth(inch)',
          'locationdepth_mm',
          'locationdepth_cm',
          'locationdepth_m',
          'locationdepth_ft',
          'locationdepth_in',
          'locationdepth_inch',
          'palletdepth',
          'palletdepth(mm)',
          'palletdepth(cm)',
          'palletdepth(m)',
          'palletdepth(ft)',
          'palletdepth(in)',
          'palletdepth(inch)',
          'palletdepth_mm',
          'palletdepth_cm',
          'palletdepth_m',
          'palletdepth_ft',
          'palletdepth_in',
          'palletdepth_inch',
          'loclength',
          'loclength',
          'loclen',
          'locl',
          'length',
          'len',
          'lgth',
          'lgt',
          'l',
          'depth',
          'dep',
          'd',
          'y',
          'sizey',
        ],
      },
      {
        ...baseFields['locationWidth'],
        title: t('Location Width', { ns: 'importer' }),
        description: t(
          'Width of the location. Please be consistent in units of measure! Typically in CM or Inches',
          { ns: 'importer' },
        ),
        example: '1000',
        aliases: [
          'locationwidth',
          'locationwidth(mm)',
          'locationwidth(cm)',
          'locationwidth(m)',
          'locationwidth(ft)',
          'locationwidth(in)',
          'locationwidth(inch)',
          'locationwidth_mm',
          'locationwidth_cm',
          'locationwidth_m',
          'locationwidth_ft',
          'locationwidth_in',
          'locationwidth_inch',
          'palletwidth',
          'palletwidth(mm)',
          'palletwidth(cm)',
          'palletwidth(m)',
          'palletwidth(ft)',
          'palletwidth(in)',
          'palletwidth(inch)',
          'locwidth',
          'locwid',
          'locwdth',
          'locw',
          'width',
          'wdth',
          'wid',
          'w',
          'szwdth',
          'x',
          'sizex',
        ],
      },
      {
        ...baseFields['locationHeight'],
        title: t('Location Height', { ns: 'importer' }),
        description: t(
          'Height of the location. Please be consistent in units of measure! Typically in CM or Inches',
          { ns: 'importer' },
        ),
        example: '1300',
        aliases: [
          'locationheight',
          'locheight',
          'lochgt',
          'loch',
          'locationheight_mm',
          'locationheight_cm',
          'locationheight_m',
          'locationheight_ft',
          'locationheight_in',
          'locationheight_inch',
          'locationheight(mm)',
          'locationheight(cm)',
          'locationheight(m)',
          'locationheight(ft)',
          'locationheight(in)',
          'locationheight(inch)',
          'palletheight',
          'palletheight(mm)',
          'palletheight(cm)',
          'palletheight(m)',
          'palletheight(ft)',
          'palletheight(in)',
          'height',
          'hgt',
          'h',
          'hght',
          'szhght',
          'z',
          'sizez',
        ],
      },
      {
        ...baseFields['locationWeight'],
        title: t('Location Weight', { ns: 'importer' }),
        description: t(
          'Weight capacity of the location. Please be consistent in units of measure! Typically in kg or lbs',
          { ns: 'importer' },
        ),
        example: '1000',
        aliases: [
          'locationweight',
          'locationweight_g',
          'locationweight_kg',
          'locationweight_kgs',
          'locationweight_lb',
          'locationweight_lbs',
          'locationweight',
          'locationweight(g)',
          'locationweight(kg)',
          'locationweight(kgs)',
          'locationweight(lb)',
          'locationweight(lbs)',
          'palletweight',
          'palletweight(g)',
          'palletweight(kg)',
          'palletweight(kgs)',
          'palletweight(lb)',
          'palletweight(lbs)',
          'max.wgt',
          'max.wgt(g)',
          'max.wgt(Kg)',
          'max.wgt(Kgs)',
          'max.wgt(lb)',
          'max.wgt(lbs)',
          'maxwgt',
          'maxwgt(g)',
          'maxwgt(Kg)',
          'maxwgt(Kgs)',
          'maxwgt(lb)',
          'maxwgt(lbs)',
          'min.wgt',
          'min.wgt(g)',
          'min.wgt(Kg)',
          'min.wgt(Kgs)',
          'min.wgt(lb)',
          'min.wgt(lbs)',
          'minwgt',
          'minwgt(g)',
          'minwgt(Kg)',
          'minwgt(Kgs)',
          'minwgt(lb)',
          'minwgt(lbs)',
          'max',
          'weight',
          'wgt',
          'wgth',
          'BLMAXQ',
        ],
      },
      {
        ...baseFields['locationAisle'],
        title: t('Location Aisle', { ns: 'importer' }),
        description: t('Aisle of the location', { ns: 'importer' }),
        example: 'AA',
        aliases: [
          'locationaisle',
          'locaisle',
          'aisle',
          'aislelocation',
          'aisleloc',
          'aisle_id',
        ],
      },
      {
        ...baseFields['locationBay'],
        title: `Bay ID`,
        description: t('Location Bay.', { ns: 'importer' }),
        example: '001',
        aliases: ['locationbay', 'locbay', 'bay', 'bayid'],
      },
      {
        ...baseFields['locationBayPosition'],
        title: t('Location Bay Position', { ns: 'importer' }),
        description: t(
          'Position of the location in the bay. Positions start from the left hand side of the bay if you face it.',
          { ns: 'importer' },
        ),
        example: '1',
        aliases: [
          'locationbayposition',
          'locationposition',
          'pos',
          'position',
          'positionlocation',
          'baylocation',
          'columnlocation',
          'baypos',
          'bayposition',
          'positioninbay',
          'position_bay',
          'positionbay',
          'pos_bay',
        ],
      },

      {
        ...baseFields['baySide'],
        title: t('Bay Side', { ns: 'importer' }),
        description: t(
          'Side of the bay in the Aisle. Can be "Left" or "Right".',
          { ns: 'importer' },
        ),
        example: 'Left',
        valueResolver: baseFields['baySide'].defaultValueResolver,
        aliases: [
          'bayside',
          'side',
          'aisleside',
          'sideaisle',
          'sidebay',
          'sidecolumn',
          'columnside',
        ],
      },
      {
        ...baseFields['locationUsageType'],
        title: t('Usage Type', { ns: 'importer' }),
        description: t(
          `Location usage type. Can be used for filtering locations. Typically has values like 'PICKING' or 'STORAGE'.`,
          { ns: 'importer' },
        ),
        example: 'PICKING',
        aliases: [
          'locationusagetype',
          'usagetype',
          'loctype',
          'locationtype',
          'typelocation',
          'typeloc',
          'typelocusage',
          'locusagetype',
          'locationprofile',
          'storagesystem',
          'cat',
          'category',
          'SZDES',
        ],
      },
      {
        ...baseFields['locationRackingType'],
        title: t('Location Type', { ns: 'importer' }),
        description: t(
          t(
            'Location Profile Types: Typically used for defining different "Shelving" and "Pallets types". Helps to customise gaps and selection of locations in filters by different profiles.',
          ),
          { ns: 'importer' },
        ),
        example: '120x100',
        aliases: [
          'locationrackingtype',
          'rackingtype',
          'locrackingtype',
          'locracktype',
          'racktype',
          'typerack',
          'typeracking',
          'type',
          'loctype',
          'locationtype',
          'location_type',
          'location_template',
          'location_profile',
          'loc_profile',
        ],
      },
      {
        ...baseFields['locationDepthPosition'],
        title: t('Location Depth Position', { ns: 'importer' }),
        description: t(
          'Depth of the location in the bay. Positions start from the left hand side of the bay if you face it.',
          { ns: 'importer' },
        ),
        example: '1',
        aliases: [
          'locationdepthposition',
          'locdepth',
          'depthposition',
          'positiondepth',
        ],
        optional: true,
      },
      {
        ...baseFields['locmhtype'],
        title: t('MHE Type', { ns: 'importer' }),
        description: t(
          'Type of material handling equipment that can access the location',
          { ns: 'importer' },
        ),
        example: 'Manual',
        aliases: [
          'locmhtype',
          'locationequipmenttype',
          'equipmenttype',
          'mhetype',
          'typeequipment',
          'locationmhetype',
          'agenttype',
          'location_class',
        ],
      },
      {
        ...baseFields['bayType'],
        title: t('Bay type', { ns: 'importer' }),
        description: t(
          'Type of the Bay to manage bay weight capacity and other bay level info',
          { ns: 'importer' },
        ),
        example: 'Racking',
        aliases: ['baytype', 'typebay'],
      },
      {
        ...baseFields['primaryAccessAisle'],
        title: t('Aisle Primary Access', { ns: 'importer' }),
        description: t(
          'Primary aisle location association, where location is accessible from (location aisle by default)',
          {
            ns: 'importer',
          },
        ),
        example: 'AA',
        aliases: ['AislePrimary', 'AislePrimaryAccess', 'AisleAccessPrimary'],
      },
      {
        ...baseFields['secondaryAccessAisle'],
        title: t('Aisle Secondary Access', { ns: 'importer' }),
        description: t(
          'Secondary aisle location association, where location is accessible from (None by default)',
          {
            ns: 'importer',
          },
        ),
        example: 'AB',
        aliases: [
          'AisleSecondary',
          'AisleSecondaryAccess',
          'AisleAccessSecondary',
        ],
      },
      {
        ...baseFields['congestionZone'],
        title: t('Workload Zone', { ns: 'importer' }),
        description: t(
          'Workload zone or congestion zone is a group of locations where there is a high concentration of activity, often requiring workload balancing to maintain efficiency and prevent bottlenecks.',
          { ns: 'importer' },
        ),
        example: 'Zone A',
        aliases: [
          'congestionZone',
          'balanceZone',
          'workloadZone',
          'congestionArea',
          'balanceArea',
          'workloadArea',
          'balance',
          'congestion',
          'workload',
          'locating_zone',
          'workzone',
          'working_zone',

          'work_zone',
        ],
      },
    ],
  };
}
