import { LayoutPlaneRouteFragment } from '@warebee/frontend/data-access-api-graphql';
import { ActivityEventProcessType } from '@warebee/shared/import-converter';
import { addHours, differenceInDays } from 'date-fns';
import _ from 'lodash';
import { atom, selector, selectorFamily } from 'recoil';
import { persistAtom } from '../../common/recoil/persistAtom';
import { viewerSelectedLocationIdAtom } from '../../layout/viewer/store/viewer.state';
import { getProductCategory } from '../../simulation/store/abc/simulation.ABC.helper';
import { warehouseSelectedId } from '../../store/warehouse.state';
import {
  actualityAgentShiftCutOf,
  actualitySelectedDocument,
} from './actuality.state';
import {
  FeedAgentShifts,
  getFeedAgentShiftsQuery,
} from './datasetQueries/feedAgentShifts';
import {
  FeedAgentSummary,
  getFeedAgentSummaryQuery,
} from './datasetQueries/feedAgentSummaryQuery';
import { getFeedAggregatedEventsQuery } from './datasetQueries/feedAggregatedEvents';
import { getFeedDateRangeQuery } from './datasetQueries/feedDateRange';
import {
  FeedDistinctAgents,
  getFeedDistinctAgentsQuery,
} from './datasetQueries/feedDistinctAgents';
import {
  FeedHeatmapCongestions,
  getFeedHeatmapCongestionsSelect,
} from './datasetQueries/feedHeatmap';
import { getFeedItemsWithRanksQuery } from './datasetQueries/feedItemsWithRank';
import {
  FeedLatestEvents,
  getLatestEventsQuery,
} from './datasetQueries/feedLatestEvents';
import {
  FeedPicklist,
  FeedPicklists,
  getFeedPicklistQuery,
} from './datasetQueries/feedPicklists';
import {
  FeedProcessTypeTotals,
  getFeedProcessTypeTotalsQuery,
} from './datasetQueries/feedProcessTypeTotals';
import {
  FeedDistinctUoms,
  getFeedDistinctUomsQuery,
} from './datasetQueries/feedUoms';
import { ActivityFeedQueryBuilderParams } from './datasetQueries/queryBuilder/feedEventsQueryBuilders';
import { ActualityStatAggregate } from './feed.default';
import {
  executeDatasetQuery,
  getAgentIntervalStats,
  getAggregatedStats,
} from './feed.helper';
import {
  ActivityFeedTabId,
  FeedActivityAggregateBy,
  FeedAgentIntervalStats,
  FeedAgentStats,
  FeedAgentStatsField,
  FeedGroupByAgentType,
  FeedItemWithABCRank,
  FeedMenuItemId,
  feedDisplayOptions,
} from './feed.types';

const getKey = (postfix: string) => `warebee-activity-${postfix}`;

export const feedDatasetId = selector<string>({
  key: getKey('dataset-id'),
  get: ({ get }) => {
    return get(actualitySelectedDocument)?.activityFeedId;
  },
});

export const feedLayoutId = selector<string>({
  key: getKey('layout-id'),
  get: ({ get }) => {
    return get(actualitySelectedDocument)?.layoutId;
  },
});

export const showKpiStatsState = persistAtom<boolean>({
  key: 'show-kpi-stats',
  default: false,
});

export const showAggState = persistAtom<boolean>({
  key: 'show-chart-aggregated',
  default: false,
});

export const activityFeedSidebarMenuState = persistAtom<boolean>({
  key: 'show-menu-feed-main',
  default: false,
});

export const feedDateRangeAtom = atom<[Date, Date]>({
  key: getKey('date-range-atom'),
  default: null,
});

export const feedDateRange = selector<[Date, Date]>({
  key: getKey('date-range'),
  get: async ({ get }) => {
    const datasetId = get(feedDatasetId);
    const setted = get(feedDateRangeAtom);
    if (!_.isNil(setted)) return setted;
    if (_.isNil(datasetId)) return [null, null];
    const warehouseId = get(warehouseSelectedId);
    const compiledQuery = getFeedDateRangeQuery(datasetId).compile();

    try {
      // console.log('[feedDateRange] Executing query with:', { warehouseId, datasetId });
      const result = await executeDatasetQuery({
        warehouseId,
        compiledQuery,
        comment: '[feed] Date Range',
      });
      // console.log('[feedDateRange] Query result:', result);

      // Check if result is not empty and has the expected properties
      if (
        result &&
        result.length > 0 &&
        result[0].minTime &&
        result[0].maxTime
      ) {
        // console.log('[feedDateRange] Returning date range:', [
        //   result[0].minTime,
        //   result[0].maxTime,
        // ]);
        return [result[0].minTime, result[0].maxTime];
      } else {
        console.warn(
          '[feedDateRange] Invalid or empty result, returning default values',
        );
        return [null, null];
      }
    } catch (ex) {
      console.error('[feedDateRange] Exception occurred:', ex);
      return [null, null];
    }
  },
});

export const feedSelectedDateRange = atom<[Date, Date]>({
  key: getKey('selected-range'),
  default: null,
});

export const feedEffectiveDateRange = selector<[Date, Date]>({
  key: getKey('effective-date-range'),
  get: async ({ get }) => {
    const [min, max] = get(feedDateRange) ?? [];
    const [calendarFrom, calendarTo] = get(feedSelectedDateRange) ?? [];
    const from = calendarFrom ?? addHours(max, -1);
    const to = calendarTo ?? max;
    return [from, to];
  },
});

export const feedIsDateRangeWithinDay = selector<boolean>({
  key: getKey('feed-is-date-range-within-day'),
  get: ({ get }) => {
    return differenceInDays(...get(feedEffectiveDateRange)) <= 1;
  },
});

export const feedSelectedAgentId = persistAtom<string>({
  key: getKey('selected-device'),
  default: null,
});

export const feedSelectedAgentIds = persistAtom<string[]>({
  key: getKey('selected-agents'),
  default: [],
});

export const feedDisabledAgentTypes = persistAtom<string[]>({
  key: getKey('selected-agent-types'),
  default: [],
});

export const feedDisabledProcessTypes = persistAtom<ActivityEventProcessType[]>(
  {
    key: getKey('selected-process-types'),
    default: [],
  },
);

export const feedSelectedAgentsDataPage = atom<number>({
  key: getKey('selected-agents-data-page'),
  default: 1,
});

export const feedSelectedPickListId = atom<string>({
  key: getKey('selected-pick-list'),
  default: null,
});

export const feedHoveredPickListId = atom<string>({
  key: getKey('hovered-pick-list'),
  default: null,
});

export const feedAgentsSummary = selector<FeedAgentSummary[]>({
  key: getKey('device-summary'),
  get: async ({ get }) => {
    const warehouseId = get(warehouseSelectedId);
    const compiledQuery = getFeedAgentSummaryQuery(
      get(feedQueryBuilderParams),
    ).compile();
    const result = await executeDatasetQuery({
      warehouseId,
      compiledQuery,
      comment: '[feed] Agent Summary',
    });
    return result;
  },
});

export const feedQueryBuilderParams = selector<ActivityFeedQueryBuilderParams>({
  key: getKey('query-builder-params'),
  get: ({ get }) => {
    const datasetId = get(feedDatasetId);

    const [from, to] = get(feedEffectiveDateRange);
    const processTypes = get(feedDisabledProcessTypes);
    const agentTypes = get(feedDisabledAgentTypes);
    return {
      datasetId,
      from,
      to,
      disabledProcessTypes: processTypes,
      disabledAgentTypes: agentTypes,
    };
  },
});

export const feedLatestEvents = selector<FeedLatestEvents>({
  key: getKey('latest-events'),
  get: async ({ get }) => {
    const warehouseId = get(warehouseSelectedId);
    const compiledQuery = getLatestEventsQuery(
      get(feedQueryBuilderParams),
    ).compile();

    const result = await executeDatasetQuery({
      warehouseId,
      compiledQuery,
      comment: '[feed] Latest Events',
    });
    return result;
  },
});

export const feedLatestAgentEvents = selector<FeedLatestEvents>({
  key: getKey('latest-agent-events'),
  get: async ({ get }) => {
    const warehouseId = get(warehouseSelectedId);
    const agentId = get(feedSelectedAgentId);
    if (_.isNil(agentId)) return [];
    const compiledQuery = getLatestEventsQuery({
      ...get(feedQueryBuilderParams),
      agentId,
    }).compile();

    const result = await executeDatasetQuery({
      warehouseId,
      compiledQuery,
      comment: '[feed] Latest Agent Events',
    });
    return result;
  },
});

export const feedLatestLocationEvents = selector<FeedLatestEvents>({
  key: getKey('latest-locations-events'),
  get: async ({ get }) => {
    const warehouseId = get(warehouseSelectedId);
    const locationId = get(viewerSelectedLocationIdAtom);
    if (_.isNil(locationId)) {
      return null;
    }
    const compiledQuery = getLatestEventsQuery({
      ...get(feedQueryBuilderParams),
      locationId,
    }).compile();

    const result = await executeDatasetQuery({
      warehouseId,
      compiledQuery,
      comment: '[feed] Latest Location Events',
    });
    return result;
  },
});

// export const feedHeatmapLocationsVisits = selector<FeedHeatmapLocationsVisits>({
//   key: getKey('locations-visits-heatmap'),
//   get: async ({ get }) => {
//     const warehouseId = get(warehouseSelectedId);
//     const compiledQuery = getFeedHeatmapVisitsQuery(
//       get(feedQueryBuilderParams),
//     ).compile();
//     const result = await executeDatasetQuery({
//       warehouseId,
//       compiledQuery,
//       comment: '[feed] Heatmap Locations Visits',
//     });
//     const visits = _(result)
//       .groupBy(row => row.locationId)
//       .map((rows, locationId) => {
//         const locVisits: FeedHeatmapLocationVisits = {
//           locationId,
//           visitsCount: _.sumBy(rows, r => r.visitsCount),
//           items: _.map(rows, row => {
//             const itemVisits: FeedHeatmapProductVisits = {
//               consignee: row.consignee,
//               sku: row.sku,
//               visitsCount: row.visitsCount,
//             };
//             return itemVisits;
//           }),
//         };
//         return locVisits;
//       })
//       .value();
//     return _.keyBy(visits, l => l.locationId);
//   },
// });

export const feedHeatmapCongestions = selector<FeedHeatmapCongestions>({
  key: getKey('device-congestion-heatmap'),
  get: async ({ get }) => {
    const warehouseId = get(warehouseSelectedId);
    const compiledQuery = getFeedHeatmapCongestionsSelect(
      get(feedQueryBuilderParams),
    ).compile();
    const result = await executeDatasetQuery({
      warehouseId,
      compiledQuery,
      comment: '[feed] Heatmap Congestions',
    });
    return result as FeedHeatmapCongestions;
  },
});

// export const feedLocationVisitMax = selector<number>({
//   key: getKey('heatmap-max-value'),
//   get: ({ get }) => {
//     const heatmap = get(feedHeatmapLocationsVisits);
//     return _.maxBy(_.values(heatmap), i => i.visitsCount)?.visitsCount ?? 0;
//   },
// });

// export const feedHeatmapBuckets = selector<Record<string, HeatmapBucket>>({
//   key: getKey('heatmap-buckets'),
//   get: ({ get }) => {
//     const metricId = get(feedHeatmapSelectedMetricId);
//     if (metricId === 'locationVisits') {
//       const max = get(feedLocationVisitMax);
//       return getVisitsHeatmapBuckets(max);
//     }
//     if (metricId === 'abc') {
//       return _.keyBy(getABCProductHeatmapBuckets(), b => b.id);
//     }
//     return {};
//   },
// });

export const feedDistinctAgents = selector<FeedDistinctAgents>({
  key: getKey('distinct-agents'),
  get: async ({ get }) => {
    const datasetId = get(feedDatasetId);
    const warehouseId = get(warehouseSelectedId);
    const compiledQuery = getFeedDistinctAgentsQuery(datasetId).compile();
    const result = await executeDatasetQuery({
      warehouseId,
      compiledQuery,
      comment: '[feed] Distinct Agents',
    });
    return result;
  },
});

export const feedDistinctUoms = selector<FeedDistinctUoms>({
  key: getKey('distinct-uoms'),
  get: async ({ get }) => {
    const datasetId = get(feedDatasetId);
    const warehouseId = get(warehouseSelectedId);
    const compiledQuery = getFeedDistinctUomsQuery(datasetId).compile();
    const result = await executeDatasetQuery({
      warehouseId,
      compiledQuery,
      comment: '[feed] Distinct Uoms ',
    });
    return result;
  },
});

export const feedAgentShifts = selector<FeedAgentShifts>({
  key: getKey('agent-shifts'),
  get: async ({ get }) => {
    const warehouseId = get(warehouseSelectedId);
    const compiledQuery = getFeedAgentShiftsQuery(
      get(feedQueryBuilderParams),
    ).compile();
    const result = await executeDatasetQuery({
      warehouseId,
      compiledQuery,
      comment: '[feed] Agent shifts ',
    });
    return result;
  },
});

//ANALYZE
// export const feedAnalyzedPicklists = selector<AnalyzedFeedPicklistFragment[]>({
//   key: getKey('analyzed-picklists'),
//   get: async ({ get }) => {
//     const whId = get(warehouseSelectedId);
//     const documentId = get(actualitySelectedId);
//     const planeId = get(viewerSelectedPlaneId);
//     const actuality = get(actualitySelectedDocument);
//     const selectedAgentId = get(feedSelectedAgentId);
//     const picklistsAll = get(feedPickListsByAgent);
//     if (_.isNil(selectedAgentId)) return null;
//     const picklists = picklistsAll[selectedAgentId];
//     if (_.isEmpty(picklists)) return null;

//     const allAgents = get(feedDistinctAgents);
//     const agentsMap = _.reduce(
//       allAgents,
//       (acc, row) => {
//         return { ...acc, [row.agentId]: row.agentType };
//       },
//       {},
//     );

//     const plToAnalyze = preparePicklists(picklists, agentsMap, planeId);
//     try {
//       const response = await secureClient.mutate<
//         AnalyzeFeedPicklistMutation,
//         AnalyzeFeedPicklistMutationVariables
//       >({
//         mutation: AnalyzeFeedPicklistDocument,
//         variables: {
//           input: {
//             layoutId: actuality.layoutId,
//             itemSetId: actuality.itemSetId,
//             resourcePolicy: cloneWithoutTypename(actuality.resourcePolicy),
//             picklists: plToAnalyze,
//             pickingPolicy: {},
//           },
//         },
//       });

//       const routes = response.data?.analyzePicklist?.picklists;
//       return routes;
//     } catch (ex) {}
//   },
// });

export const proglovePicklistRoutesById = selector<
  Record<string, LayoutPlaneRouteFragment[]>
>({
  key: getKey('picklist-routes'),
  get: async ({ get }) => {
    return {};
    // const layoutId = get(feedLayoutId);
    // const picklistsAll = get(feedPickListsByAgent);
    // const picklists = _(picklistsAll).values().flatten().value();

    // if (_.isEmpty(picklists)) return null;
    // const locations = _.map(picklists, pl =>
    //   _.map(pl.lines, l => l.locationId),
    // );

    // const picklistBatchSize = 50;

    // const batches = _.range(Math.ceil(_.size(locations) / picklistBatchSize));

    // const routes = [];
    // try {
    //   for (const batchIndex of batches) {
    //     const locationBatch = _.slice(
    //       locations,
    //       batchIndex * picklistBatchSize,
    //       Math.min((batchIndex + 1) * picklistBatchSize, _.size(locations)),
    //     );

    //     const response = await secureClient.query<
    //       BatchBuildLayoutRouteQuery,
    //       BatchBuildLayoutRouteQueryVariables
    //     >({
    //       query: BatchBuildLayoutRouteDocument,
    //       variables: {
    //         input: {
    //           layoutId,
    //           includeTerminals: false,
    //           agentId: 'foo',
    //           locations: locationBatch,
    //           routingPolicy: {},
    //           order: LayoutRouteOrder.KEEP_ORDER,
    //         },
    //       },
    //     });

    //     routes.push(
    //       ..._.map(response.data?.batchBuildLayoutRoute, r => r.routes),
    //     );
    //   }
    //   const routesByPicklistId = _.reduce(
    //     picklists,
    //     (acc, pl, index) => {
    //       return { ...acc, [pl.id]: routes[index] };
    //     },
    //     {} as Record<string, LayoutPlaneRouteFragment[]>,
    //   );
    //   return routesByPicklistId;
    // } catch (ex) {}
  },
});

export const feedPicklistsSummary = selector<FeedPicklists>({
  key: getKey('picklists-summary'),
  get: async ({ get }) => {
    const warehouseId = get(warehouseSelectedId);
    const compiledQuery = getFeedPicklistQuery(
      get(feedQueryBuilderParams),
    ).compile();

    const result = await executeDatasetQuery({
      warehouseId,
      compiledQuery,
      comment: '[feed] Picklists Summary',
    });
    return result;
  },
});

export const feedItemsWithRank = selector<FeedItemWithABCRank[]>({
  key: getKey('items-with-rank'),
  get: async ({ get }) => {
    const warehouseId = get(warehouseSelectedId);
    const compiledQuery = getFeedItemsWithRanksQuery(
      get(feedQueryBuilderParams),
    ).compile();

    const result = await executeDatasetQuery({
      warehouseId,
      compiledQuery,
      comment: '[feed] Items With Rank',
    });

    return _.map(result, item => {
      const withAbc: FeedItemWithABCRank = {
        ...item,
        category: getProductCategory(item.cmlPercentRank)?.key,
      };
      return withAbc;
    });
  },
});

export const feedItemsWithRankMap = selector<
  Record<string, FeedItemWithABCRank>
>({
  key: getKey('items-with-rank-map'),
  get: async ({ get }) => {
    const itemsWithRank = get(feedItemsWithRank);
    return _.keyBy(itemsWithRank, 'skuKey');
  },
});

export const feedPicklistsSummaryByAgent = selector<
  Record<string, FeedPicklists>
>({
  key: getKey('picklists-summary-by-agent'),
  get: async ({ get }) => {
    const all = get(feedPicklistsSummary);
    const grouped = _.groupBy(all, p => p.agentId);
    return grouped;
  },
});

export const feedPicklistsSummaryByAgentType = selector<
  Record<string, FeedPicklists>
>({
  key: getKey('picklists-summary-by-agent-type'),
  get: async ({ get }) => {
    const all = get(feedPicklistsSummary);
    const grouped = _.groupBy(all, p => p.agentType);
    return grouped;
  },
});

export const feedPicklistsSummaryById = selector<Record<string, FeedPicklist>>({
  key: getKey('picklists-summary-by-job-id'),
  get: async ({ get }) => {
    const all = get(feedPicklistsSummary);
    const grouped = _.keyBy(all, p => p.jobId) as Record<string, FeedPicklist>;
    return grouped;
  },
});

export const feedStatsAggregateBy = persistAtom<ActualityStatAggregate>({
  key: getKey('stats-aggregate-by'),
  default: 'agent',
});

export const feedAgentsStatsSortBy = atom<FeedAgentStatsField>({
  key: getKey('agents-stats-sort-by'),
  default: 'totalUoms',
});

export const feedAgentsStatsSortDirection = atom<'asc' | 'desc'>({
  key: getKey('agents-stats-sort-direction'),
  default: 'desc',
});

export const feedAgentsStats = selector<FeedAgentStats[]>({
  key: getKey('agents-stats'),
  get: ({ get }) => {
    // const analyzedJobs = get()
    const aggregateBy = get(feedStatsAggregateBy);
    const pickLists =
      aggregateBy === 'agent'
        ? get(feedPicklistsSummaryByAgent)
        : get(feedPicklistsSummaryByAgentType);
    const range = get(feedEffectiveDateRange);
    const cutOfHours = get(actualityAgentShiftCutOf);
    const stats = getAggregatedStats(pickLists, range, cutOfHours);
    const sortBy = get(feedAgentsStatsSortBy);
    const sortDirection = get(feedAgentsStatsSortDirection);
    const sorted = _.orderBy(stats, s => s[sortBy], sortDirection);
    return sorted;
  },
});

export const feedTotalStats = selector<FeedAgentStats>({
  key: getKey('total-stats'),
  get: ({ get }) => {
    const all = get(feedPicklistsSummary);
    const range = get(feedEffectiveDateRange);
    const cutOfHours = get(actualityAgentShiftCutOf);
    const stats = getAggregatedStats({ all: all }, range, cutOfHours);
    return _.head(stats);
  },
});

export const feedAggregateBy = atom<FeedActivityAggregateBy>({
  key: getKey('aggregate-by'),
  default: 'hour',
});

export const feedAggregatedEvents = selector<FeedAgentIntervalStats>({
  key: getKey('aggregated-events'),
  get: async ({ get }) => {
    const warehouseId = get(warehouseSelectedId);
    const aggBy = get(feedAggregateBy);
    const compiledQuery = getFeedAggregatedEventsQuery(
      get(feedQueryBuilderParams),
    ).compile();

    const result = await executeDatasetQuery({
      warehouseId,
      compiledQuery,
      comment: '[feed] Aggregated Events ',
    });

    return getAgentIntervalStats(result, aggBy);
  },
});

export const feedProcessTypeTotals = selector<FeedProcessTypeTotals>({
  key: getKey('process-type-totals'),
  get: async ({ get }) => {
    const warehouseId = get(warehouseSelectedId);
    const compiledQuery = getFeedProcessTypeTotalsQuery(
      get(feedQueryBuilderParams),
    ).compile();

    const result = await executeDatasetQuery({
      warehouseId,
      compiledQuery,
      comment: '[feed] Process Type Totals',
    });
    return result;
  },
});

// Menu
const feedSelectedMenuItemIdAll = persistAtom<Record<string, FeedMenuItemId>>({
  key: getKey('selected-menu-item-id-all'),
  default: {},
});

export const feedSelectedMenuItemId = selectorFamily<FeedMenuItemId, string>({
  key: getKey('selected-menu-item-id'),
  get:
    (layoutId: string) =>
    ({ get }) => {
      return get(feedSelectedMenuItemIdAll)?.[layoutId] || 'feed-menu-activity';
    },

  set:
    (layoutId: string) =>
    ({ get, set }, value: FeedMenuItemId) => {
      set(feedSelectedMenuItemIdAll, {
        ...get(feedSelectedMenuItemIdAll),
        [layoutId]: value,
      });
    },
});

const feedActivitySelectedTabIdAtom = persistAtom<ActivityFeedTabId>({
  key: getKey('feed-tab-key-atom'),
  default: 'tab-feed-activity',
});

export const feedDisabledAgentStatsFields = persistAtom<FeedAgentStatsField[]>({
  key: 'disabled-agent-stats-fields',
  default: [],
});

export const feedActivitySelectedTabId = selector<ActivityFeedTabId>({
  key: getKey('feed-tab-key'),
  get: ({ get }) => get(feedActivitySelectedTabIdAtom),
  set: ({ get, set }, value: ActivityFeedTabId) => {
    console.log(
      'SELECTOR feedActivitySelectedTabId:',
      get(feedActivitySelectedTabIdAtom),
      value,
    );
    set(feedActivitySelectedTabIdAtom, current => {
      console.log('SELECTOR feedActivitySelectedTabId CURRENT:', current);
      return value;
    });
  },
});

export enum KpiSummaryModes {
  All = 'All',
  Summary = 'Summary',
  Detailed = 'Detailed',
}

export const showEventsKpiSummaryChart = persistAtom<KpiSummaryModes>({
  key: getKey('events-kpi-summary-chart-mode'),
  default: KpiSummaryModes.All,
});

export type FeedDisplayOption = (typeof feedDisplayOptions)[number]['value'];

export const feedDisplayOptionAtom = persistAtom<FeedDisplayOption>({
  key: getKey('feed-display-option'),
  default: 'all',
});

export const feedGroupByAgentType = persistAtom<FeedGroupByAgentType>({
  key: 'feedGroupByAgentType',
  default: 'agent',
});
