import {
  AllocationRunResultDeallocatedReason,
  AllocationRunResultUnallocatedReason,
} from '@warebee/frontend/data-access-api-graphql';
import { AllocationSummaryDataRow } from '@warebee/shared/export-converter';
import classNames from 'classnames';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { getQualitativeColor } from '../../../common/color.helper';
import { formatInteger } from '../../../common/formatHelper';
import useFormatter from '../../../common/useFormatter';
import { ColumnConfig } from '../../../components/DatasetTable';
import Tag from '../../../helpContext/Tag';
import {
  getAllocationSummaryGroutByOptions,
  getDeallocatedReasonOptions,
  getUnallocatedReasonOptions,
} from '../../store/allocation/allocation.helper';
import {
  AllocationMetricDescriptor,
  AllocationSummaryGroupByType,
} from '../../store/allocation/allocation.types';
import EmptyValueTag from '../../tags/EmptyValueTag';
import ItemTag from '../../tags/ItemTag';
import UomTag from '../../tags/UomTag';

export type AllocationSummaryTableConfigParams = {
  key: AllocationSummaryGroupByType;
  metric: AllocationMetricDescriptor;
};

function renderUoms(uoms: Record<string, number>) {
  if (_.isEmpty(uoms)) {
    return <EmptyValueTag />;
  }
  return (
    <>
      {_.map(uoms, (value, uom) => (
        <UomTag key={uom} uom={uom} value={value} className="w-full" />
      ))}
    </>
  );
}

function renderQtyValue(value: number) {
  if (_.isNil(value) || value == 0) {
    return <EmptyValueTag />;
  }
  return (
    <span className="flex-1 text-right" title={`RAW: ${formatInteger(value)}`}>
      {Number(value) > 1000000
        ? formatInteger(Number(value))
        : formatInteger(Number(value))}
    </span>
  );
}

function renderUnallocatedReasons(
  reasons: AllocationRunResultUnallocatedReason[],
  t: TFunction<'simulation'>,
) {
  const reasonOptions = getUnallocatedReasonOptions(t);

  if (!reasons || reasons.length === 0) {
    return null;
  }

  return (
    <>
      {reasons.map(reason => {
        const reasonOption = reasonOptions.find(option => option.id === reason);
        return (
          <Tag key={reason}>{reasonOption ? reasonOption.title : reason}</Tag>
        );
      })}
    </>
  );
}

function renderDeallocatedReasons(
  reasons: AllocationRunResultDeallocatedReason[],
  t: TFunction<'simulation'>,
) {
  const reasonOptions = getDeallocatedReasonOptions(t);

  if (!reasons || reasons.length === 0) {
    return null;
  }

  return (
    <>
      {reasons.map(reason => {
        const reasonOption = reasonOptions.find(option => option.id === reason);
        return (
          <Tag key={reason}>{reasonOption ? reasonOption.title : reason}</Tag>
        );
      })}
    </>
  );
}

function renderUomsWithTotal(
  uoms: Record<string, number>,
  total: number | null,
  t: TFunction<'simulation'>,
  className?: string,
) {
  return (
    <div
      className={`flex flex-1 flex-col items-end justify-self-start whitespace-normal ${className || ''}`}
    >
      <div className="flex w-full flex-1 flex-col flex-wrap items-end">
        {renderUoms(uoms)}
      </div>
      {total != null && total !== 0 && (
        <div className="mt-1 flex-1">
          <UomTag uom={t`Total`} value={total} className="!bg-transparent" />
        </div>
      )}
    </div>
  );
}

function useAllocationSummaryTableConfig(
  params: AllocationSummaryTableConfigParams,
): ColumnConfig<AllocationSummaryDataRow>[] {
  const { t } = useTranslation('simulation');
  const formatter = useFormatter();

  const keyOptionsAll = getAllocationSummaryGroutByOptions(t);
  const keyOption = keyOptionsAll[params.key];

  const keyFields = new Set(keyOption.keyColumns);
  const keyFieldsAll: ColumnConfig<AllocationSummaryDataRow>[] = [
    {
      field: 'consignee',
      title: t`Consignee`,
      isHeader: true,
      // hasSort: true,
      hasFilter: true,
    },
    {
      field: 'sku',
      title: t`Item (SKU)`,
      isHeader: true,
      hasSort: true,
      hasFilter: true,
      render: (sku: string, row) => (
        <ItemTag title={sku} filters={{ consignee: row['consignee'], sku }} />
      ),
    },
    { field: 'skuGroup', title: t`Item Group`, hasSort: true, hasFilter: true },
    { field: 'subGroup', title: t`Sub-Group`, hasSort: true, hasFilter: false },

    {
      field: 'transportClass',
      title: t`Transport Class`,
      hasSort: true,
      hasFilter: true,
    },
    {
      field: 'stockCategory',
      title: t`Stock Category`,
      hasSort: true,
      hasFilter: true,
    },
    {
      field: 'storageClass',
      title: t`Storage Class`,
      hasSort: true,
      hasFilter: true,
    },
    {
      field: 'pollutionClass',
      title: t`Pollution Class`,
      hasSort: true,
      hasFilter: true,
    },
  ];

  const dataFieldsAll: ColumnConfig<AllocationSummaryDataRow>[] = [
    // Stock

    {
      field: 'initiallyPickableLocationCount',
      title: t`Stock Locations`,
      render: renderQtyValue,
    },
    {
      field: 'allocatedLocationCount',
      title: t`Allocated (Loc.)`,
      hasSort: true,
      render: renderQtyValue,
    },
    {
      field: 'allocatedSharedLocationCount',
      title: t`Shared (Loc.)`,
      render: renderQtyValue,
    },
    {
      field: 'deallocatedLocationCount',
      title: t`Removed (Loc.)`,
      render: renderQtyValue,
    },

    {
      field: 'initiallyPickableFulfilledQty',
      title: t`Initial (Qty)`,
      hasSort: true,
      render: (value, row, index) =>
        renderUomsWithTotal(
          row.initiallyPickableFulfilledUOM,
          Number(value),
          t,
        ),
      hiddenInExport: true,
      alignCell: 'align-bottom',
    },
    {
      field: 'initiallyPickableFulfilledQty',
      title: t`Initial (Qty)`,
      hiddenInBrowser: true,
    },
    {
      field: 'initiallyPickableFulfilledUOMString',
      title: t`Initial (Qty)`,
      hiddenInBrowser: true,
    },

    // Ordered
    {
      field: 'requiredQty',
      title: t`Ordered (qty)`,
      hasSort: true,
      render: (value, row, index) =>
        renderUomsWithTotal(row.requiredUOM, Number(value), t),
      hiddenInExport: true,
      alignCell: 'align-bottom',
    },

    {
      field: 'requiredQty',
      title: t`Ordered (qty)`,
      hasSort: true,
      render: renderQtyValue,
      hiddenInBrowser: true,
    },

    // {
    //   field: 'requiredUOM',
    //   title: t`Ordered UOM`,
    //   render: renderUoms,
    //   hiddenInExport: true,
    // },
    {
      field: 'requiredUOMString',
      title: t`Ordered UOM`,
      hiddenInBrowser: true,
    },

    // {
    //   field: 'initiallyPickableUOM',
    //   title: t`Initial UOM`,
    //   render: renderUoms,
    //   hiddenInExport: true,
    // },
    {
      field: 'initiallyPickableUOMString',
      title: t`Initial UOM`,
      hiddenInBrowser: true,
    },

    //toAllocateRequiredUOM
    {
      field: 'toAllocateRequiredQty',
      title: t`Allocate Required (Qty)`,
      hasSort: true,
      render: renderQtyValue,
      hiddenInBrowser: true,
    },
    {
      field: 'toAllocateRequiredQty',
      title: t`To Allocate (req.)`,
      hasSort: true,
      render: (value, row, index) =>
        renderUomsWithTotal(
          row.toAllocateRequiredUOM,
          Number(value) || null,
          t,
        ),
      hiddenInExport: true,
      alignCell: 'align-bottom',
    },
    // {
    //   field: 'toAllocateRequiredUOM',
    //   title: t`Allocate Required UOM`,
    //   render: renderUoms,
    //   hiddenInExport: true,
    // },
    {
      field: 'toAllocateRequiredUOMString',
      title: t`Allocate Required UOM`,
      hiddenInBrowser: true,
    },

    //toAllocate
    {
      field: 'toAllocateQty',
      title: t`Allocate (Qty)`,
      hasSort: true,
      render: renderQtyValue,
      hiddenInBrowser: true,
    },
    {
      field: 'toAllocateQty',
      title: t`To Allocate`,
      hasSort: true,
      render: (value, row, index) =>
        renderUomsWithTotal(row.toAllocateUOM, Number(value), t),
      hiddenInExport: true,
      alignCell: 'align-bottom',
      classNameTd: '!bg-state-allocated/[0.1]',
    },
    // {
    //   field: 'toAllocateUOM',
    //   title: t`Allocate UOM`,
    //   render: renderUoms,
    //   hiddenInExport: true,
    // },
    {
      field: 'toAllocateUOMString',
      title: t`Allocate UOM`,
      hiddenInBrowser: true,
    },

    {
      field: 'allocatedFulfilledQty',
      title: t`Allocated`,
      hasSort: true,
      render: (value, row, index) =>
        renderUomsWithTotal(row.allocatedFulfilledUOM, Number(value), t),
      hiddenInExport: true,
      alignCell: 'align-bottom',
      classNameTd: '!bg-alerts-compliant/[0.08]',
    },

    {
      field: 'allocatedFulfilledQty',
      title: t`Allocated (Qty)`,
      hiddenInBrowser: true,
    },
    {
      field: 'allocatedFulfilledUOMString',
      title: t`Allocated UOM`,
      hiddenInBrowser: true,
    },

    {
      field: 'unAllocatedQty',
      title: t`Remaining (Qty)`,
      hasSort: true,
      render: renderQtyValue,
      hiddenInBrowser: true,
    },
    {
      field: 'unAllocatedQty',
      title: t`Remaining`,
      hasSort: true,
      render: (value, row, index) =>
        renderUomsWithTotal(row.unAllocatedUOM, Number(value) || null, t),
      hiddenInExport: true,
      alignCell: 'align-bottom',
      classNameTd: '!bg-alerts-overlap/[0.05]',
    },
    // {
    //   field: 'unAllocatedUOM',
    //   title: t`Remaining UOM`,
    //   render: renderUoms,
    //   hiddenInExport: true,
    // },
    {
      field: 'unAllocatedUOMString',
      title: t`Remaining UOM`,
      hiddenInBrowser: true,
    },
    {
      field: 'unAllocatedReasons',
      title: t`Remaining (Details)`,
      render: (v: AllocationRunResultUnallocatedReason[]) =>
        renderUnallocatedReasons(v, t),
      hasFilter: true,
      hiddenInExport: true,
      classNameTd: '!bg-alerts-overlap/[0.05]',
    },
    {
      field: 'unAllocatedReasonsString',
      title: t`Remaining Reason`,
      hiddenInBrowser: true,
    },

    //toReallocateQty
    {
      field: 'toReallocateQty',
      title: t`Move (Stock) (Qty)`,
      hasSort: true,
      render: renderQtyValue,
      hiddenInBrowser: true,
    },
    {
      field: 'toReallocateQty',
      title: t`To Move`,
      hasSort: true,
      render: (value, row, index) =>
        renderUomsWithTotal(row.toReallocateUOM, Number(value), t),
      hiddenInExport: true,
      alignCell: 'align-bottom',
    },
    // {
    //   field: 'toReallocateUOM',
    //   title: t`Move (Stock) UOM`,
    //   render: renderUoms,
    //   hiddenInExport: true,
    // },
    {
      field: 'toReallocateUOMString',
      title: t`Move (Stock) UOM`,
      hiddenInBrowser: true,
    },

    {
      field: 'deallocatedQty',
      title: t`Removed`,
      hasSort: true,
      render: (value, row, index) =>
        renderUomsWithTotal(row.deallocatedUOM, Number(value) || null, t),
      hiddenInExport: true,
      alignCell: 'align-bottom',
    },

    {
      field: 'deallocatedQty',
      title: t`Removed (Qty)`,
      hasSort: true,
      render: renderQtyValue,
      hiddenInBrowser: true,
    },
    // {
    //   field: 'deallocatedUOM',
    //   title: t`Removed UOM`,
    //   render: renderUoms,
    //   hiddenInExport: true,
    // },
    {
      field: 'deallocatedUOMString',
      title: t`Removed UOM`,
      hiddenInBrowser: true,
    },
    {
      field: 'deallocatedReasons',
      title: t`Removed Reasons`,
      hasFilter: true,
      render: (v: AllocationRunResultDeallocatedReason[]) =>
        renderDeallocatedReasons(v, t),
      hiddenInExport: true,
    },
    {
      field: 'deallocatedReasonsString',
      title: t`Removed Reasons`,
      hiddenInBrowser: true,
    },
    {
      field: 'allocatedSharedQty',
      title: t`Shared`,
      hasSort: true,
      render: renderQtyValue,
    },
    {
      field: 'unpickableQty',
      title: t`Unpickable`,
      hasSort: true,
      render: renderQtyValue,
    },
  ];

  const visibleColumnsSet = new Set(params.metric.visibleTableColumns);

  const dataFields =
    visibleColumnsSet.size === 0
      ? dataFieldsAll
      : _.filter(dataFieldsAll, f => visibleColumnsSet.has(f.field as any));
  const columnsConfig: ColumnConfig<AllocationSummaryDataRow>[] = [
    {
      field: 'key',
      title: '',
      hiddenInExport: true,
      isHeader: true,
      render: (key, row) => {
        const dimension = keyOption.getMetricValue(row);
        const colors = getQualitativeColor(dimension, 'dimension');
        return (
          <div
            className={classNames('flex h-6 w-1 rounded-sm px-1 py-1')}
            style={{
              backgroundColor: colors[0],
              color: colors[1],
            }}
          />
        );
      },
    },
    ..._.filter(keyFieldsAll, f => keyFields.has(f.field as any)),
    ...dataFields,
  ];

  return columnsConfig;
}

export default useAllocationSummaryTableConfig;
