import {
  AllocationLimitApplyTo,
  AllocationRequirementAggregationMethod,
  AllocationRuleFragment,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import DropdownSelector from '../../../../components/actions/DropdownSelector';
import { InputGroupList } from '../../../../components/inputs/InputGroupList';
import InputNumber from '../../../../components/inputs/InputNumber';
import SectionOptional from '../../../../components/layout/SectionOptional';
import TitleSection from '../../../../components/layout/TitleSection';
import {
  allocationPolicyRule,
  allocationPolicySelectedIdentity,
} from '../../../store/allocationPolicy/allocationPolicy.state';

const AllocationPolicyLimitsEditor: React.FC = () => {
  const { t } = useTranslation('simulation');
  const selectedIdentity = useRecoilValue(allocationPolicySelectedIdentity);
  const [rule, updateRule] = useRecoilState(
    allocationPolicyRule(selectedIdentity?.ruleId),
  );

  function updateAllocationSettings(patch: Partial<AllocationRuleFragment>) {
    updateRule(current => ({
      ...current,
      ...patch,
    }));
  }

  if (!selectedIdentity || !rule) return null;

  const applyToOptions: AllocationLimitApplyTo[] = [
    AllocationLimitApplyTo.REQUIREMENT,
    AllocationLimitApplyTo.RULE,
  ];
  const applyToTitles: Record<AllocationLimitApplyTo, string> = {
    RULE: t`Rule`,
    REQUIREMENT: t`Requirement`,
  };

  return (
    <TitleSection
      id={`policy-editor-limits`}
      title={t`Allocation Limits`}
      inSidebarView
      hasScreenTitle
    >
      <InputGroupList className="space-y-2">
        {/* Average Settings*/}
        <SectionOptional
          id={'allocation-use-average'}
          title={t`Allocate daily average`}
          value={
            rule?.requirementAggregationSettings?.aggregation ===
            AllocationRequirementAggregationMethod.DAILY_AVERAGE
          }
          onChange={enabled =>
            updateAllocationSettings({
              requirementAggregationSettings: {
                ...rule?.requirementAggregationSettings,
                aggregation: enabled
                  ? AllocationRequirementAggregationMethod.DAILY_AVERAGE
                  : null,
                numberOfPeriods: 1,
              },
            })
          }
        >
          <InputNumber
            title={t`Days count`}
            value={rule?.requirementAggregationSettings?.numberOfPeriods}
            range={[0, 1000]}
            onChange={v =>
              updateAllocationSettings({
                requirementAggregationSettings: {
                  ...rule?.requirementAggregationSettings,
                  numberOfPeriods: v,
                },
              })
            }
          />
        </SectionOptional>

        <DropdownSelector
          value={
            rule?.limitSettings?.applyTo ?? AllocationLimitApplyTo.REQUIREMENT
          }
          values={applyToOptions}
          renderValue={(id: string) => applyToTitles[id]}
          hasSubAction
          DropAlignRight
          widthFull
          valuePrimary
          multiline
          light
          onChange={(applyTo: AllocationLimitApplyTo) =>
            updateAllocationSettings({
              limitSettings: {
                ...rule?.limitSettings,
                applyTo,
              },
            })
          }
        />

        {/* LIMIT ALLOCATED ASSIGNMENTS*/}
        <SectionOptional
          id={'allocation-limit-by-allocated-assignments'}
          title={t`Limit allocated assignment`}
          value={!_.isNil(rule?.limitSettings?.maxAllocatedAssignments)}
          onChange={enabled =>
            updateAllocationSettings({
              limitSettings: {
                ...rule?.limitSettings,
                maxAllocatedAssignments: enabled ? 1 : null,
              },
            })
          }
        >
          <InputNumber
            title={t`Max Allocated per item`}
            value={rule?.limitSettings?.maxAllocatedAssignments}
            range={[0, 1000]}
            onChange={v =>
              updateAllocationSettings({
                limitSettings: {
                  ...rule?.limitSettings,
                  maxAllocatedAssignments: v,
                },
              })
            }
          />
        </SectionOptional>

        {/* LIMIT PICKABLE ASSIGNMENTS*/}
        <SectionOptional
          id={'allocation-limit-by-pickable-assignments'}
          title={t`Limit total assignment`}
          value={!_.isNil(rule?.limitSettings?.maxPickableAssignments)}
          onChange={enabled =>
            updateAllocationSettings({
              limitSettings: {
                ...rule?.limitSettings,
                maxPickableAssignments: enabled ? 1 : null,
              },
            })
          }
        >
          <InputNumber
            title={t`Max pickable assignments per item`}
            value={rule?.limitSettings?.maxPickableAssignments}
            range={[0, 1000]}
            onChange={v =>
              updateAllocationSettings({
                limitSettings: {
                  ...rule?.limitSettings,
                  maxPickableAssignments: v,
                },
              })
            }
          />
        </SectionOptional>

        {/* MAX ALLOCATION PER ROUND */}
        <SectionOptional
          id={'allocation-per-round'}
          title={t`Specify allocation per round`}
          value={!_.isNil(rule?.roundSettings?.maxAssignmentsPerRound)}
          onChange={enabled =>
            updateAllocationSettings({
              roundSettings: {
                ...rule?.roundSettings,
                maxAssignmentsPerRound: enabled ? 1 : null,
              },
            })
          }
        >
          <InputNumber
            title={t`Max allocation per round`}
            value={rule?.roundSettings?.maxAssignmentsPerRound}
            range={[0, 1000]}
            onChange={v =>
              updateAllocationSettings({
                roundSettings: {
                  ...rule?.roundSettings,
                  maxAssignmentsPerRound: v,
                },
              })
            }
          />
        </SectionOptional>
      </InputGroupList>
    </TitleSection>
  );
};

export default AllocationPolicyLimitsEditor;
