import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { formatInteger } from '../../../common/formatHelper';
import { cn } from '../../../common/utils';
import ErrorIndicator from '../../../components/ErrorIndicator';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import { Container } from '../../../components/layout/ContainerFlex';
import { Stat } from '../../../components/stats/Stat';
import { StatListItem } from '../../../components/stats/StatListItem';
import { getAllocationMetricDescriptors } from '../../store/allocation/allocation.helper';
import { allocationRunSummary } from '../../store/allocation/allocation.state';
import { AllocationMetric } from '../../store/allocation/allocation.types';
import AllocationSummaryView from './AllocationSummaryView';

const AllocationSummary: React.FC = () => {
  const { t } = useTranslation('simulation');
  const allocationRun = useRecoilValue(allocationRunSummary);

  const isAllocating =
    allocationRun?.status === 'CALCULATING' ||
    allocationRun?.status === 'CREATED' ||
    allocationRun?.status === 'RESTARTED';

  const hasError =
    allocationRun?.status === 'FAILED' ||
    allocationRun?.status === 'TERMINATED';

  const isLoading = isAllocating;

  const summary = allocationRun?.resultRequirementsSummary?.[0];

  const metricDescriptors = getAllocationMetricDescriptors(t);

  const renderUomStats = (uoms: any[]) => {
    return uoms.map(uom => (
      <StatListItem
        key={`uom-${uom.uom}`}
        value={formatInteger(uom.uomQuantity)}
        title={uom.uom}
      />
    ));
  };

  const renderStat = (key: AllocationMetric, data: any) => {
    if (!data || data.totalQuantity === 0) return null;

    const descriptor = metricDescriptors[key];
    if (!descriptor) return null;

    return (
      <Stat
        title={descriptor.title}
        key={`allocated-${key}`}
        value={
          data.totalQuantity > 1000000
            ? formatInteger(data.totalQuantity)
            : formatInteger(data.totalQuantity)
        }
      >
        {/* {data.totalLocationCount && (
          <StatListItem
            value={formatInteger(data.totalLocationCount)}
            label="Locations"
          />
        )} */}
        {data.uoms && renderUomStats(data.uoms)}
      </Stat>
    );
  };

  const metricKeys: AllocationMetric[] = [
    'all',
    'required',
    'assigned',
    'initiallyPickable',
    'toAllocateRequired',
    'toReallocate',
    'toAllocate',
    'allocated',
    'allocatedShared',
    'reallocated',
    'unallocated',
    'deallocated',
    'unpickable',
  ];

  const summaryKeyMapping: Partial<
    Record<AllocationMetric, keyof typeof summary>
  > = {
    required: 'required',
    assigned: 'initiallyPickable',
    initiallyPickable: 'initiallyPickable',
    toAllocateRequired: 'toAllocateRequired',
    toReallocate: 'toReallocate',
    toAllocate: 'toAllocate',
    allocated: 'allocated',
    allocatedShared: 'allocatedShared',
    reallocated: 'toReallocate',
    unallocated: 'unallocated',
    deallocated: 'deallocated',
    unpickable: 'unpickable',
  };

  return (
    <>
      <Container
        col
        overflow
        componentName={`AllocationSummary`}
        className={cn(
          'relative',
          'flex-1',
          'border-app-panel-dark',
          'ltr:border-l rtl:border-r',
        )}
      >
        {hasError && <ErrorIndicator message="" selfCenter />}

        {isLoading && (
          <LoadingIndicator absolute selfCenter message={t`Allocating...`} />
        )}
        <header className="my-1 flex flex-wrap gap-1">
          {metricKeys.map(key =>
            renderStat(key, summary?.[summaryKeyMapping[key]]),
          )}
        </header>
        <AllocationSummaryView />
      </Container>
    </>
  );
};

export default AllocationSummary;
