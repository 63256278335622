import classNames from 'classnames';
import _ from 'lodash';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import AllocationPolicyLocationsStatsByRuleTableView from '../allocation/AllocationPolicyLocationsStatsByRuleTableView';
import AnalyzedProductsTable from '../analyze/AnalyzedProductsTable';
import AssignmentDataTable from '../assignment/AssignmentDataTable';
import AssignmentFilterTag from '../assignment/AssignmentFilterTag';
import AssignmentPolicyLocationsStatsByRuleTableView from '../assignmentPolicy/AssignmentPolicyLocationsStatsByRuleTableView';
import { panelFixedHeightsCss } from '../common/component.types';
import ButtonLocateLocation from '../components/ButtonLocateLocation';
import LoadingIndicator from '../components/LoadingIndicator';
import { PanelHeader } from '../components/designer/panels/PanelHeader';
import { ContainerCol } from '../components/layout/ContainerFlex';
import ItemSetDataTable from '../itemSet/ItemSetDataTable';
import ItemSetFilterTag from '../itemSet/ItemSetFilterTag';
import LayoutDataTable from '../layout/LayoutDataTable';
import LayoutFilterTag from '../layout/LayoutFilterTag';
import OrderSetDataTable from '../orders/OrderSetDataTable';
import OrderSetFilterTag from '../orders/OrderSetFilterTag';
import DatasetLocationsStatsTableView from './DatasetLocationsStatsTableView';
import SimulationAssignmentSelect from './SimulationAssignmentSelect';
import { optimisationAnalyzeId } from './store/optimisation.state';
import {
  simulationAnalyzeId,
  simulationCurrent,
  simulationEffectiveAssignmentId,
  simulationExtraDataViewHeight,
  simulationShowDatasetAsTable,
} from './store/simulation.state';

const SimulationExtraDatasetView: React.FC = () => {
  const { t } = useTranslation('simulation');
  const sim = useRecoilValue(simulationCurrent);
  const effectiveAssignmentId = useRecoilValue(simulationEffectiveAssignmentId);
  const [showDatasetTable, setShowDatasetTable] = useRecoilState(
    simulationShowDatasetAsTable,
  );
  const [height, setHeight] = useRecoilState(simulationExtraDataViewHeight);

  const analyzeId = useRecoilValue(simulationAnalyzeId);
  const optimiseAnalyzeId = useRecoilValue(optimisationAnalyzeId);

  if (_.isNil(showDatasetTable)) return null;

  function genPanelTitle() {
    switch (showDatasetTable) {
      case 'assignment':
        return t`Assignment`;
      case 'item-set':
        return t`Items`;
      case 'layout':
        return t`Locations`;
      case 'locations-stats-all':
        return t`Location Stats: Capacity & Occupancy`;
      case 'locations-stats-by-rule':
      case 'allocation-locations-stats-by-rule':
        return t`Rule Stats: Capacity & Occupancy`;
      case 'order-set':
        return t`Orders`;
      case 'analyzed-location-products':
        return t`Analyzed Products`;
      case 'optimized-location-products':
        return t`Optimised Products`;
    }
  }

  function genPanelTitleContent() {
    switch (showDatasetTable) {
      case 'assignment':
        return <SimulationAssignmentSelect />;
    }
    return null;
  }

  function getFilterTags() {
    switch (showDatasetTable) {
      case 'layout':
        return <LayoutFilterTag />;
      case 'assignment':
        return <AssignmentFilterTag />;
      case 'item-set':
        return <ItemSetFilterTag />;
      case 'order-set':
        return <OrderSetFilterTag />;
    }
  }

  return (
    <ContainerCol
      overflow
      componentName={`DatasetTableContainer`}
      fullHeight={false}
      className={classNames(
        height !== 'h-min' ? 'min-h-20' : '',
        'border-app-panel-dark ltr:border-l rtl:border-r',
        'relative',
        height ? panelFixedHeightsCss[height] : null,
      )}
    >
      <Suspense
        fallback={
          <LoadingIndicator
            className="absolute left-0 right-0 top-[45%] m-auto max-h-28 max-w-10 shadow-2xl"
            absolute
            selfCenter
            backdrop
            message={t`Loading Simulation Data...`}
          />
        }
      >
        <PanelHeader
          title={genPanelTitle()}
          closable={true}
          onCloseClick={() => setShowDatasetTable(null)}
          filterPlaceholder={getFilterTags()}
          adjustable={!_.isNil(height)}
          fixedHeight={height}
          onHeightChange={setHeight}
        >
          {genPanelTitleContent()}
        </PanelHeader>
        {height !== 'h-min' && (
          <>
            {showDatasetTable === 'layout' && (
              <LayoutDataTable
                id={sim?.layout?.id}
                title={t`Locations`}
                hideScreenTitle={true}
              />
            )}
            {showDatasetTable === 'assignment' && (
              <AssignmentDataTable
                id={effectiveAssignmentId}
                title={t`Assignment`}
                hideScreenTitle={true}
                customRenders={{
                  locationId: (locationId: string, row) => (
                    <ButtonLocateLocation locationId={locationId} />
                  ),
                }}
              />
            )}
            {showDatasetTable === 'order-set' && (
              <OrderSetDataTable
                id={sim?.orderSet?.id}
                title={t`Orders`}
                hideScreenTitle={true}
              />
            )}
            {showDatasetTable === 'item-set' && (
              <ItemSetDataTable
                id={sim?.itemSet?.id}
                title={t`Items`}
                hideScreenTitle={true}
              />
            )}
            {showDatasetTable === 'locations-stats-by-rule' && (
              <AssignmentPolicyLocationsStatsByRuleTableView />
            )}
            {showDatasetTable === 'locations-stats-all' && (
              <DatasetLocationsStatsTableView />
            )}
            {showDatasetTable === 'analyzed-location-products' && (
              <AnalyzedProductsTable analyzeId={analyzeId} />
            )}
            {showDatasetTable === 'optimized-location-products' && (
              <AnalyzedProductsTable analyzeId={optimiseAnalyzeId} />
            )}
            {showDatasetTable === 'allocation-locations-stats-by-rule' && (
              <AllocationPolicyLocationsStatsByRuleTableView />
            )}
          </>
        )}
      </Suspense>
    </ContainerCol>
  );
};

export default SimulationExtraDatasetView;
