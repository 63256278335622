import {
  AllocationRunBaseFragment,
  AllocationSummaryFragment,
  AnalyzeResultFragment,
} from '@warebee/frontend/data-access-api-graphql';
import { AllocationSummaryDataColumn } from '@warebee/shared/export-converter';
import { atom, selector } from 'recoil';
import { AsyncLoadStatus, DatasetTableState } from '../../../common/types';
import {
  AllocationMetric,
  AllocationSummaryGroupByType,
} from './allocation.types';

const getKey = (postfix: string) => `warebee-simulation-allocation-${postfix}`;

export const allocationRunSummary = atom<AllocationRunBaseFragment>({
  key: getKey('run-summary'),
  default: null,
});

export const allocationRunSummaryLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('run-summary-status'),
  default: AsyncLoadStatus.None,
});

export const allocationAnalyzeResultAtom = atom<AnalyzeResultFragment>({
  key: getKey('analyze-result-atom'),
  default: null,
});

export const allocationAnalyzeResult = selector<AnalyzeResultFragment>({
  key: getKey('analyze-result'),
  get: ({ get }) => {
    return get(allocationAnalyzeResultAtom);
  },
  set: ({ set }, value) => {
    set(allocationAnalyzeResultAtom, value);
  },
});

export const allocationAssignmentId = selector<string>({
  key: getKey('assignment-id'),
  get: ({ get }) => get(allocationRunSummary)?.resultAssignment?.id,
});

export const allocationAnalyzeId = selector<string>({
  key: getKey('analyze-id'),
  get: ({ get }) => get(allocationAnalyzeResultAtom)?.id,
});

export const allocationSummaryGroupBy = atom<AllocationSummaryGroupByType>({
  key: getKey('summary-group-by'),
  default: 'item',
});

export const allocationSummarySelectedMetric = atom<AllocationMetric>({
  key: getKey('summary-metric-selected'),
  default: 'all',
});

export const allocationSummaryData = atom<AllocationSummaryFragment[]>({
  key: getKey('summary-data'),
  default: null,
});

export const allocationSummaryDataLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('summary-load-status'),
  default: AsyncLoadStatus.None,
});

export const allocationSummaryTableState = atom<
  DatasetTableState<AllocationSummaryDataColumn>
>({
  key: getKey('summary-table-state'),
  default: {
    searchValues: {},
    sortValues: {},
  },
});
