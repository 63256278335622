import { SortDirection } from '@warebee/frontend/data-access-api-graphql';
import { atom, selector } from 'recoil';
import { AsyncLoadStatus, DataTableState } from '../../common/types';
import {
  actualityMainViewType,
  actualitySelectedDocument,
} from './actuality.state';
import {
  ActualityHqDataColumn,
  ActualityHqDataRows,
} from './datasetQueries/actualityHqDataRows';
import { ActualityHqQueryBuilderParams } from './datasetQueries/actualityQueryBuilder';
import { feedQueryBuilderParams } from './feed.state';

const getKey = (postfix: string) => `warebee-actuality-hq-${postfix}`;

export const actualityHqBuilderParams = selector<ActualityHqQueryBuilderParams>(
  {
    key: getKey('hq-query-builder-params'),
    get: ({ get }) => {
      const actuality = get(actualitySelectedDocument);
      const queryParamsBase = get(feedQueryBuilderParams);
      return {
        ...queryParamsBase,
        itemSetId: actuality.itemSetId,
        layoutId: actuality.layoutId,
        assignmentId: actuality.assignmentId,
      };
    },
  },
);

export const actualityShowHqTable = selector<boolean>({
  key: getKey('show-hq-table'),
  get: ({ get }) => {
    return get(actualityMainViewType) === 'table';
  },
});

export const actualityHqTableData = atom<ActualityHqDataRows>({
  key: getKey('hq-table-data'),
  default: [],
});

export const actualityHqTableTotalCount = atom<number>({
  key: getKey('hq-table-total-count'),
  default: 0,
});

export const actualityHqTableDataLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('hq-table-data-status'),
  default: AsyncLoadStatus.None,
});

export const actualityHqTableDataState = atom<
  DataTableState<ActualityHqDataColumn>
>({
  key: getKey('hq-table-data-state'),
  default: {
    sortValues: {
      eventEndTime: SortDirection.DESC,
    },
    searchValues: {},
  },
});
